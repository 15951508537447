import React, { Component } from "react";
import avatar4 from "../assets/images/avatar/04.jpg";
import avatar2 from "../assets/images/avatar/02.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

class Clients extends Component {
  state = {};
  render() {
    return (
      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="section-title center">
                <h2 className="title">Clients Say About Us</h2>
                <p>
                  Focus is having the unwavering attention to complete what you
                  set out to do.
                </p>
              </div>
            </div>
          </div>
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={1000}
          >
            <div>
              <img src={avatar4} />
              <div className="myCarousel">
                <h3>Felica Queen</h3>
                <h4>Product Designer</h4>
                <p>
                  Their turnaround time for fixing any issue is just a few
                  minutes, and that is appreciable. Their Business Development
                  Team is always there to help at any point in time. Thank you
                  so much for all your effort.
                </p>
              </div>
            </div>

            <div>
              <img src={avatar2} />
              <div className="myCarousel">
                <h3>John Doe</h3>
                <h4>Graphic Designer</h4>
                <p>
                  CG Job Portal is an excellent job portal. We value the resumes
                  received through this channel. Magic Search and Power search
                  are handy tools. We are delighted with their service.
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </section>
    );
  }
}

export default Clients;

//     <div className="row justify-content-center">
//       <div className="col-lg-10">
//         <div className="owl-carousel owl-nav-top-center" data-nav-arrow="true"  data-items="1" data-md-items="1" data-sm-items="1" data-xs-items="1" data-xx-items="1" data-space="0">
//           <div className="item">
//             <div className="testimonial-item text-center">
//               <div className="avatar">
//                 <img className="img-fluid rounded-circle" src={avatar4} alt=""/>
//               </div>
//               <div className="testimonial-content">
//                 <p>Their turnaround time for fixing any issue is just a few minutes, and that is appreciable. Their Business Development Team is always there to help at any point in time. Thank you so much for all your effort.</p>
//               </div>
//               <div className="testimonial-name">
//                 <i className="fas fa-quote-left quotes"></i>
//                 <h6 className="mb-1">Felica Queen</h6>
//                 <span>Product Designer</span>
//               </div>
//             </div>
//           </div>
//           <div className="item">
//             <div className="testimonial-item text-center">
//               <div className="avatar">
//                 <img className="img-fluid rounded-circle" src={avatar2} alt=""/>
//               </div>
//               <div className="testimonial-content">
//                 <p>CG Job Portal is an excellent job portal. We value the resumes received through this channel. Magic Search and Power search are handy tools. We are delighted with their service.</p>
//               </div>
//               <div className="testimonial-name">
//                 <i className="fas fa-quote-left quotes"></i>
//                 <h6 className="mb-1">John Doe</h6>
//                 <span>Graphic Designer</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </section>
