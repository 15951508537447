import React, { Component } from "react";
import { Link } from "react-router-dom";
import svg1 from "../assets/images/svg/01.svg";
import svg2 from "../assets/images/svg/02.svg";
import svg3 from "../assets/images/svg/03.svg";
import svg4 from "../assets/images/svg/04.svg";
import svg5 from "../assets/images/svg/05.svg";
import svg6 from "../assets/images/svg/06.svg";
import svg19 from "../assets/images/svg/19.svg";
import svg8 from "../assets/images/svg/08.svg";
import svg10 from "../assets/images/svg/10.svg";
import svg9 from "../assets/images/svg/09.svg";

class Erightside extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="col-lg-9">
          <div className="row mb-4">
            <div className="col-12">
              <h6 className="mb-0">
                Showing 1-10 of{" "}
                <span className="text-primary">28 Employer</span>
              </h6>
            </div>
          </div>
          <div className="job-filter mb-4 d-sm-flex align-items-center">
            <div className="job-alert-bt">
              {" "}
              <Link className="btn btn-md btn-dark" to="#">
                <i className="fa fa-envelope"></i>Get job alert{" "}
              </Link>{" "}
            </div>
            <div className="job-shortby ml-sm-auto d-flex align-items-center">
              <form className="form-inline">
                <div className="form-group mb-0">
                  <label className="justify-content-start mr-2">
                    Sort by :
                  </label>
                  <div className="short-by">
                    <select className="form-control basic-select">
                      <option>Newest</option>
                      <option>Oldest</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="employers-grid text-center">
                <div className="employers-list-logo">
                  <img className="img-fluid" src={svg1} alt="" />
                </div>
                <div className="employers-list-details">
                  <div className="employers-list-info">
                    <div className="employers-list-title">
                      <h5 className="mb-0">
                        <Link to="Employerdetail">
                          Fast Systems Consultants
                        </Link>
                      </h5>
                    </div>
                    <div className="employers-list-option">
                      <ul className="list-unstyled">
                        <li>
                          <i className="fas fa-filter pr-1"></i>Accountancy
                        </li>
                        <li>
                          <i className="fas fa-map-marker-alt pr-1"></i>
                          Wellesley Rd, London
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="employers-list-position">
                  <Link className="btn btn-sm btn-dark" to="#">
                    17 Open position
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="employers-grid text-center">
                <div className="employers-list-logo">
                  <img className="img-fluid" src={svg2} alt="" />
                </div>
                <div className="employers-list-details">
                  <div className="employers-list-info">
                    <div className="employers-list-title">
                      <h5 className="mb-0">
                        <Link to="employer-detail.html">
                          Pendragon Green Ltd
                        </Link>
                      </h5>
                    </div>
                    <div className="employers-list-option">
                      <ul className="list-unstyled">
                        <li>
                          <i className="fas fa-filter pr-1"></i>Accountancy
                          (Qualified)
                        </li>
                        <li>
                          <i className="fas fa-map-marker-alt pr-1"></i>Needham,
                          MA
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="employers-list-position">
                  <Link className="btn btn-sm btn-dark" to="#">
                    20 Open position
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="employers-grid text-center">
                <div className="employers-list-logo">
                  <img className="img-fluid" src={svg3} alt="" />
                </div>
                <div className="employers-list-details">
                  <div className="employers-list-info">
                    <div className="employers-list-title">
                      <h5 className="mb-0">
                        <Link to="employer-detail.html">
                          Wight Sound Hearing LLC
                        </Link>
                      </h5>
                    </div>
                    <div className="employers-list-option">
                      <ul className="list-unstyled">
                        <li>
                          <i className="fas fa-filter pr-1"></i>Admin,
                          Secretarial
                        </li>
                        <li>
                          <i className="fas fa-map-marker-alt pr-1"></i>New
                          Castle, PA
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="employers-list-position">
                  <Link className="btn btn-sm btn-dark" to="#">
                    23 Open position
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="employers-grid text-center">
                <div className="employers-list-logo">
                  <img className="img-fluid" src={svg4} alt="" />
                </div>
                <div className="employers-list-details">
                  <div className="employers-list-info">
                    <div className="employers-list-title">
                      <h5 className="mb-0">
                        <Link to="employer-detail.html">Tan Electrics Ltd</Link>
                      </h5>
                    </div>
                    <div className="employers-list-option">
                      <ul className="list-unstyled">
                        <li>
                          <i className="fas fa-filter pr-1"></i>Apprenticeships
                        </li>
                        <li>
                          <i className="fas fa-map-marker-alt pr-1"></i>Park
                          Avenue, Mumbai
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="employers-list-position">
                  <Link className="btn btn-sm btn-dark" to="#">
                    25 Open position
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="employers-grid text-center">
                <div className="employers-list-logo">
                  <img className="img-fluid" src={svg5} alt="" />
                </div>
                <div className="employers-list-details">
                  <div className="employers-list-info">
                    <div className="employers-list-title">
                      <h5 className="mb-0">
                        <Link to="employer-detail.html">
                          Northwood (Torbay) Ltd
                        </Link>
                      </h5>
                    </div>
                    <div className="employers-list-option">
                      <ul className="list-unstyled">
                        <li>
                          <i className="fas fa-filter pr-1"></i>Banking
                        </li>
                        <li>
                          <i className="fas fa-map-marker-alt pr-1"></i>Green
                          Lanes, London
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="employers-list-position">
                  <Link className="btn btn-sm btn-dark" to="#">
                    28 Open position
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="employers-grid text-center">
                <div className="employers-list-logo">
                  <img className="img-fluid" src={svg6} alt="" />
                </div>
                <div className="employers-list-details">
                  <div className="employers-list-info">
                    <div className="employers-list-title">
                      <h5 className="mb-0">
                        <Link to="employer-detail.html">NameThe Raj Douth</Link>
                      </h5>
                    </div>
                    <div className="employers-list-option">
                      <ul className="list-unstyled">
                        <li>
                          <i className="fas fa-filter pr-1"></i>Charity &
                          Voluntary
                        </li>
                        <li>
                          <i className="fas fa-map-marker-alt pr-1"></i>Ormskirk
                          Rd, Wigan
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="employers-list-position">
                  <Link className="btn btn-sm btn-dark" to="#">
                    30 Open position
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="employers-grid text-center">
                <div className="employers-list-logo">
                  <img className="img-fluid" src={svg19} alt="" />
                </div>
                <div className="employers-list-details">
                  <div className="employers-list-info">
                    <div className="employers-list-title">
                      <h5 className="mb-0">
                        <Link to="employer-detail.html">
                          NameThe Bridalworks
                        </Link>
                      </h5>
                    </div>
                    <div className="employers-list-option">
                      <ul className="list-unstyled">
                        <li>
                          <i className="fas fa-filter pr-1"></i>Construction &
                          Property
                        </li>
                        <li>
                          <i className="fas fa-map-marker-alt pr-1"></i>Taunton,
                          London
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="employers-list-position">
                  <Link className="btn btn-sm btn-dark" to="#">
                    34 Open position
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="employers-grid text-center">
                <div className="employers-list-logo">
                  <img className="img-fluid" src={svg8} alt="" />
                </div>
                <div className="employers-list-details">
                  <div className="employers-list-info">
                    <div className="employers-list-title">
                      <h5 className="mb-0">
                        <Link to="employer-detail.html">
                          Carphone Warehouse
                        </Link>
                      </h5>
                    </div>
                    <div className="employers-list-option">
                      <ul className="list-unstyled">
                        <li>
                          <i className="fas fa-filter pr-1"></i>Customer Service
                        </li>
                        <li>
                          <i className="fas fa-map-marker-alt pr-1"></i>
                          Botchergate, Carlisle
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="employers-list-position">
                  <Link className="btn btn-sm btn-dark" to="#">
                    40 Open position
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="employers-grid text-center">
                <div className="employers-list-logo">
                  <img className="img-fluid" src={svg9} alt="" />
                </div>
                <div className="employers-list-details">
                  <div className="employers-list-info">
                    <div className="employers-list-title">
                      <h5 className="mb-0">
                        <Link to="employer-detail.html">Bright Sparks PLC</Link>
                      </h5>
                    </div>
                    <div className="employers-list-option">
                      <ul className="list-unstyled">
                        <li>
                          <i className="fas fa-filter pr-1"></i>Education
                        </li>
                        <li>
                          <i className="fas fa-map-marker-alt pr-1"></i>Paris,
                          Île-de-France
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="employers-list-position">
                  <Link className="btn btn-sm btn-dark" to="#">
                    45 Open position
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="employers-grid text-center">
                <div className="employers-list-logo">
                  <img className="img-fluid" src={svg10} alt="" />
                </div>
                <div className="employers-list-details">
                  <div className="employers-list-info">
                    <div className="employers-list-title">
                      <h5 className="mb-0">
                        <Link to="employer-detail.html">
                          Wollens Building Supplies
                        </Link>
                      </h5>
                    </div>
                    <div className="employers-list-option">
                      <ul className="list-unstyled">
                        <li>
                          <i className="fas fa-filter pr-1"></i>Energy
                        </li>
                        <li>
                          <i className="fas fa-map-marker-alt pr-1"></i>Union
                          St, New Delhi
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="employers-list-position">
                  <Link className="btn btn-sm btn-dark" to="#">
                    50 Open position
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-12 text-center mt-0">
              <ul className="pagination justify-content-center">
                <li className="page-item disabled">
                  {" "}
                  <span className="page-link b-radius-none">Prev</span>{" "}
                </li>
                <li className="page-item active" aria-current="page">
                  <span className="page-link">1 </span>{" "}
                  <span className="sr-only">(current)</span>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    2
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    3
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    ...
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    25
                  </Link>
                </li>
                <li className="page-item">
                  {" "}
                  <Link className="page-link" to="#">
                    Next
                  </Link>{" "}
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}

export default Erightside;
