import React, { Component } from "react";
import Header from "./header";
import Joi from "joi-browser";
import jwt from "jsonwebtoken";
import logo from "../assets/images/logo.png";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fresherloginService from "../services/fresherloginService";
import exploginService from "../services/exploginService";
import Jsregister from "../components/jtab";


class JobSeeker extends Component {
  state = {
    email: "",
    password: "",
    types: ["Select", "Fresher", "Experience"],
    errors: {},
    open1: false,
    type: "",
  };
  schema = {
    email: Joi.string().required().label("Email"),
    password: Joi.string().required().label("Password"),
  };

  handletypes = (e) => {
    if (e.target.value) {
      this.setState({ type: e.target.value });
    }
  };

  doSubmit = async () => {
    try {
      const obj = {
        email: this.state.email,
        password: this.state.password,
      };

      if (this.state.type === "exp") {
        const response = await exploginService.explogin(obj);
      } else {
        const response = await fresherloginService.frelogin(obj);
      }

      toast.success("successfully login", {
        position: toast.POSITION.TOP_RIGHT,
      });
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  render() {
    return (
      <React.Fragment>
        <div class="login-register">
          <div class="form-group ">
            <label class="form-label">Email address</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Your Email"
              label="Email"
              value={this.state.email}
              onChange={(email) => this.setState({ email: email.target.value })}
            />
          </div>
          <div class="form-group">
            <label class="form-label">Password</label>
            <input
              type="password"
              class="form-control"
              placeholder="Enter Your Password"
              value={this.state.password}
              onChange={(password) =>
                this.setState({
                  password: password.target.value,
                })
              }
            />
          </div>
          <div class="form-group">
            <label class="form-label">Type</label>

            <select
              className="form-control"
              onChange={(e) => this.handletypes(e)}
            >
              {this.state.types.map((i) => (
                <option value={i}>{i}</option>
              ))}
            </select>
          </div>
          <div class="form-row">
            <div class="col-md-4">
              <button onClick={this.doSubmit} class="btn btn-primary btn-xs">
                Sign In
              </button>
            </div>
            <div class="col-md-8">
              <div class=" mt-3 mt-md-0 forgot-pass">
                <p class="mt-1">
                  Don't have account? <Link to="/jtab">Sign Up here</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default JobSeeker;
