import React, { Component } from 'react';
import StepZilla from 'react-stepzilla';
import PersonalDetails from './personal';
import Step2 from './step2';
import Step3 from './step3';
import { toast } from "react-toastify";
import { eregister } from "../../services/experienceService";

class Stepper extends Component {
  state = {
    name: "",
    email: "",
    password: "",
    contactNumber: "",
    resumeURL: "",
    Gender: "",
    preferedworklocation:"",
    skills:"",
    gender: ["Select","Male", "Female", "Others"],
    // employement:{
      designation:"",
    companyname:"",
    annualsalary:"",
    workingsince:"",
    cityworkedin:"",
    noticeperiod:"",
    employementtype:"",
    describeyourrole:"",
       
      Highestqualifications:["Select",
      "Doctorate",
      "Masters/Post-Graduation",
      "Graduation/Diploma"],
      course:"",
      Course:["Select",
        "Ph.D/Doctorate",
        "MPHIL",
        "MS/M.Sc (Science)",
  "MBA/PGDM",
  "M.A",
	"M.Com",
	"MCA",
	"M.Tech",
	"Integrated PG",
  "PG Diploma",
  "B.Tech/B.E.",
"B.Sc",
"B.Com",
"Diploma",
"BCA",
"B.B.A/B.M.S",
"B.Ed",
"B.Pharma",
	      "Other"
      ],
      specialization:"",
      Specialization:["Select",
      "Advertising/Mass Communication",
        "Agriculture",
        "Anthropology",
        "Architecture",
        "Arts & Humanities",
        "Astrophysics",
        "Automobile",
        "Aviation",
        "Bio-Chemistry/Bio-Technology",
        "Biomedical",
        "Biophysics",
        "Biotechnology",
        "Botany",
      "Ceramics",
      "Aerospace & Mechanical Engineering",
"Agriculture",
"Anthropology",
"Astronautical Engineering",
"Bio-chemistry",
"Biology",
"Biotechnology",
"Botany",
"Chemical Engineering",
"Cyber Security Engineering",
"Computers",
"Dairy Technology",
"Data Informatics",
"Electrical Engineering",
"Electronics",
"Mechanical Engineering",
"Information Technology",
"Agriculture",
"Automation",
"Ceramics",
"Chemical",
"Computers",
"Electrical",
"Electronics/Telecommunication",
"Mechanical",
"Others",
      ],
      college:"",
      coursetype:"",
      passingyear:"",
     
    var: "",
  temp: "",
  exp: "",
  };

  handlegender = async(e) => {
      await this.setState({ Gender: e.target.value});
      console.log(this.state.Gender);
  };
  radiohandleChange3 = async (value) => {
    await this.setState({ exp: value });
    console.log(this.state.exp);
  };
  radiohandleChange = async (value) => {
    await this.setState({ var: value });
    console.log(this.state.var);
  };
  handlehighestqualifications = (e) => {
    if (e.target.value) {
      this.setState({ highestQualifications: e.target.value });
    }
  };
  handleCourses = (e) => {
    if (e.target.value) {
      this.setState({ course: e.target.value });
    }
  };
  handleSpecializations = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      this.setState({ specialization: e.target.value });
    }
  };

  handleSubmit = async () => {
  // await this.setState({expbtn:"true"})
  if (this.state) {
  }
  try {
    const employement={
      designation:this.state.designation,
    companyname:this.state.companyname,
    annualsalary:this.state.annualsalary,
    workingsince:this.state.workingsince,
    cityworkedin:this.state.cityworkedin,
    noticeperiod:this.state.noticeperiod,
    employementtype:this.state.employementtype,
    describeyourrole:this.state.describeyourrole
    }
    const edu={
      Highestqualifications:this.state.Highestqualifications,
      Course:this.state.Course,
      Specialization:this.state.Specialization,
      college:this.state.college,
      coursetype:this.state.coursetype,
      passingyear:this.state.passingyear

    }
    const data = {
      name: this.state.name,
      contactNumber: this.state.contactNumber,
      password: this.state.password,
      email: this.state.email,
      gender:this.state.Gender,
      preferedworklocation:this.state.preferedworklocation,
      skills:this.state.skills,
      employement:employement,
      education:edu,
      resumeURL:this.state.resumeURL
      
    };

    const response = await eregister(data);
    // auth.emploginWithJwt(response.headers["x-auth-token"]);
    // localStorage.setItem("token", jwt);
    if(!response){
      toast.error("fill all details", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else{
    toast.success("successfully register", {
      position: toast.POSITION.TOP_RIGHT,
    });

    setTimeout(() => {
      window.location = "/";
    }, 5000);
  }
 } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
};
  formsubmit = (step) => {
    if (step === 1) {
      console.log(this.props);
      
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className='step-progress'>
          <StepZilla
            
            steps={[
              { name: 'PersonDetails', component:  <div className="row">
          
              <div className="col-md-4">
                <label className="text-start text-left">Name *</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="exampleInputName"
                  placeholder="Please enter your Name"
                  value={this.state.name}
                  onChange={(name) => this.setState({ name: name.target.value })}
                />
              </div>
              <div className="col-md-4">
                <label className="text-start">Email *</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="exampleInputName"
                  placeholder="Please enter your Email"
                  value={this.state.email}
                  onChange={(email) => this.setState({ email: email.target.value })}
                />
              </div>
              <div className="col-md-4">
                <label className="text-start">Password *</label>
                <input
                  type="password"
                  className="form-control form-control-sm"
                  id="exampleInputName"
                  placeholder="Please enter your Password"
                  value={this.state.password}
                  onChange={(password) => this.setState({ password: password.target.value })}
                />
              </div>
              <div className="col-md-4">
            <label className="text-start">Contact Number*</label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="exampleInputName"
              placeholder="Please enter your Contact Number"
              value={this.state.contactNumber}
              onChange={(contactNumber) => this.setState({ contactNumber: contactNumber.target.value })}
            />
          </div>
          <div className="col-md-4">
            <label className="text-start">Skills*</label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="exampleInputName"
              placeholder="Please enter your Skills"
              value={this.state.skills}
              onChange={(skills) => this.setState({ skills: skills.target.value })}
            />
          </div>
          <div className="col-md-4">
            <label className="text-start">Prefered Work Location *</label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="exampleInputName"
              placeholder="Please enter your Prefered Work Location"
              value={this.state.preferedworklocation}
              onChange={(preferedworklocation) => this.setState({ preferedworklocation: preferedworklocation.target.value })}
            />
          </div>
          
          <div className="form-group col-md-4 select-border">
              <label>Gender *</label>
              <select
                className="form-control"
                onChange={(e) => this.handlegender(e)}>
                {this.state.gender.map((i) => (
                  <option value={i} required>{i}</option>
                ))}
              </select>
            </div>
            <div className="form-group col-md-4">
              <label>Upload Resume *</label>
              <input
                type="file"
                className="form-control"
                placeholder="Please choose your Resume"
                value={this.state.resumeURL}
                onChange={(resumeURL) =>
                  this.setState({ resumeURL: resumeURL.target.value })
                }
              />
            </div>
            </div> },
              { name: 'EmployementDetails', component: <div className="row">
             
             <div className="form-group col-md-4">
        <label for="exampleInput">Designation*</label>
        <input
          type="text"
          className="form-control form-control-sm"
          id="exampleInputName"
          placeholder="Please enter your Designation"
          value={this.state.designation}
          onChange={(designation) => this.setState({ designation: designation.target.value })}
        />
      </div>
      
      <div className="form-group col-md-4">
        <label for="exampleInput">Company Name*</label>
        <input
          type="text"
          className="form-control form-control-sm"
          id="exampleInputName"
          placeholder="Please enter your Company Name"
          value ={this.state.companyname}
          onChange={(companyname) => this.setState({ companyname: companyname.target.value })}
        />
      </div>
      <div className="form-group col-md-4">
        <label for="exampleInputPassword1">Annual Salary*</label>
        <input
          type="text"
          className="form-control form-control-sm"
          id="exampleInputName"
          placeholder="Please enter your Annual Salary"
          value={this.state.annualsalary}
          onChange={(annualsalary) => this.setState({ annualsalary: annualsalary.target.value })}
        />
      </div>
      <div className="form-group col-md-4">
        <label for="exampleInputPassword1">Working Since*</label>
        <input
          type="text"
          className="form-control form-control-sm"
          id="exampleInputName"
          placeholder="Please enter your Working Since"
          value={this.state.workingsince}
          onChange={(workingsince) => this.setState({ workingsince: workingsince.target.value })}
        />
      </div>
      <div className="form-group col-md-4">
        <label for="exampleInputPassword1">City WorkedIn</label>
        <input
          type="text"
          className="form-control form-control-sm"
          id="exampleInputName"
          placeholder="Please enter your City Worked In"
          value={this.state.cityworkedin}
          onChange={(cityworkedin) => this.setState({ cityworkedin: cityworkedin.target.value })}
        />
      </div>
      <div className="form-group col-md-4"
        role="group"
        aria-label="Basic radio toggle button group"
      >
        <label>Employement Type *</label>
        <div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOption3"
              id="inlineRadio3"
              value={this.state.exp}
              onChange={() => this.radiohandleChange3("Fulltime")}
            />
            <label class="form-check-label " for="inlineRadio1">
              Fulltime
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOption3"
              id="inlineRadio3"
              value={this.state.exp}
              onChange={() => this.radiohandleChange3("Parttime")}
            />
            <label class="form-check-label" for="inlineRadio2">
              Parttime
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOption3"
              id="inlineRadio3"
              value={this.state.exp}
              onChange={() => this.radiohandleChange3("Both")}
            />
            <label class="form-check-label" for="inlineRadio3">
              Both
            </label>
          </div>
        </div>
      </div>
      
      <div className="form-group col-md-4">
        <label for="exampleInputPassword1">Notice Period</label>
        <input
          type="text"
          className="form-control form-control-sm"
          id="exampleInputName"
          placeholder="Please enter your Notice Period"
           value={this.state.noticeperiod}
          onChange={(noticeperiod) => this.setState({ noticeperiod: noticeperiod.target.value })}
        />
      </div>
      <div className="form-group col-md-4">
        <label for="exampleInputPassword1">Describe Your Role</label>
        <textarea
          type="text"
          className="form-control form-control-sm"
          id="exampleInputName"
          placeholder="Describe your Role"
          value={this.state.Describeyourrole}
          onChange={(Describeyourrole) => this.setState({ Describeyourrole: Describeyourrole.target.value })}
        ></textarea>
      </div>
      </div> },
              { name: 'EducationDetails', component: <div className="row">
                <div className="form-group col-md-4 select-border">
              <label>Highest Qualification *</label>
              <select
                className="form-control"
                onChange={(e) => this.handlehighestqualifications(e)}
              >
                {this.state.Highestqualifications.map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>
            </div>
                    
            <div className="form-group col-md-4 select-border">
              <label>Course *</label>
             
              <select
                className="form-control"
                onChange={(e) => this.handleCourses(e)}
              >
                {this.state.Course.map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>
            </div>
            <div className="form-group col-md-4 select-border">
              <label>Specialization *</label>
              <select
                className="form-control"
                onChange={(e) => this.handleSpecializations(e)}
              >
                {this.state.Specialization.map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>
            </div>
           
            <div className="form-group col-md-4">
              <label for="exampleInputPassword1">College/University*</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your College"
                value={this.state.college}
                onChange={(college) =>
                  this.setState({ college: college.target.value })
                }
              />
            </div>
            <div
              className="col-md-4"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <label>Course Type *</label>
              <div className="row">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value={this.state.var}
                    onChange={() => this.radiohandleChange("Fulltime")}
                  />
                  <label class="form-check-label " for="inlineRadio1">
                    Full Time
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value={this.state.var}
                    onChange={() => this.radiohandleChange("Parttime")}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Part Time
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    value={this.state.var}
                    onChange={() => this.radiohandleChange("correspondence")}
                  />
                  <label class="form-check-label" for="inlineRadio3">
                    Correspondence
                  </label>
                </div>
              </div>
              </div>
              <div className="form-group col-md-4">
              <label for="exampleInputPassword1">Passing Year</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your Passing Year"
                value={this.state.passingyear}
                onChange={(passingyear) => this.setState({ passingyear: passingyear.target.value })}
              />
            </div>
                <button
              type="button"
              className="btn btn-primary col-sm-2"
             onClick={this.handleSubmit}
            >
              Submit
            </button> 
            </div>},
            ]}
           
            stepsNavigation={true}
            onStepChange={(step) => this.formsubmit(step)}
            nextTextOnFinalActionStep='Save'
            preventEnterSubmission={true}
            prevBtnOnLastStep={false}
            preventEnterSubmission={true}
          />
        </div>
       
      </React.Fragment>
    );
  }
}

export default Stepper;
