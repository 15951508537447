import React, { Component } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from './components/home';
import './App.css';
import './assets/images/favicon.ico';
import './assets/css/font-awesome/all.min.css';
import './assets/css/flaticon/flaticon.css';
import './assets/css/bootstrap/bootstrap.min.css';
import './assets/css/owl-carousel/owl.carousel.min.css';
import './assets/css/magnific-popup/magnific-popup.css';
import './assets/css/style.css';
import Employer from './components/employer';
import Fresher from './components/fresher';
import Experience from './components/experience';
import Button from './components/Buttons';
import Postjob from './components/postjob';
import JobSeeker from "./components/jobseeker";
import Jobs from "./components/jobs";
import ViewJobList from "./components/viewjoblist";
import JobDetail from "./components/jobdetail";
import JobList from "./components/joblist";
import Sector from "./components/sector";
import SideBar from "./components/sidebar";
import TJobList from "./components/tjoblist";
import Logout from "./components/logout";
import Profile from "./components/candidateprofile/profile";
import Changepassword from "./components/candidateprofile/changepassword";
import CandidateDashboard from "./components/candidateprofile/candidatedashboard";
import Companies from "./components/companies";
import Employerdetail from "./components/employerdetail";
import Viewapplicants from "./components/viewapplicants";
import Search from "./components/searchpage";
import Banner from "./components/banner";
import Sjoblist from "./components/sjoblist";
import SsideBar from "./components/ssidebar";
import Stepper from "./components/stepper/index";
import Step1 from './components/stepper/step1';
import Step2 from './components/stepper/step2';
import Tas from "./components/jtab";
import Stepperf from "./components/stepper/freindex";
import PersonalDetails from "./components/stepper/personal";
import EducationDetails from "./components/stepper/step3";



class App extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        <Route exact path="/" component={Home} history={this.props.history}/>
        <Switch>
        <Route exact path="/employer" component={Employer} />
        <Route exact path="/fresher" component={Fresher} />
        <Route exact path="/experience" component={Experience} />
        <Route exact path="/button" component={Button} />
        <Route exact path="/postjob" component={Postjob} />
        <Route exact path="/jobseeker" component={JobSeeker}/>
        <Route exact path="/jobs" component={Jobs}/>
        <Route exact path="/sector" component={Sector} history={this.props.history}/>
        <Route exact path="/joblist" component={JobList} history={this.props.history}/>
        <Route exact path="/viewjoblist" component={ViewJobList}/>
        <Route exact path="/jobdetail" component={JobDetail} history={this.props.history}/>
        <Route exact path="/sidebar" component={SideBar} history={this.props.history}/>
        <Route exact path="/tjoblist" component={TJobList} history={this.props.history}/>
        <Route exact path="/logout" component={Logout}/>
        <Route exact path="/profile" component={Profile}/>
        <Route exact path="/changepassword" component={Changepassword} />
        <Route exact path="/candidatedashboard" component={CandidateDashboard}/>
        <Route exact path="/companies" component={Companies} />
        <Route exact path="/employerdetail" component={Employerdetail}/>
        <Route exact path="/viewapplicants" component={Viewapplicants}/>
        <Route exact path="/searchpage" component={Search} history={this.props.history}/>
        <Route exact path="/banner" component={Banner} history={this.props.history}/>
        <Route exact path="/sjoblist" component={Sjoblist} history={this.props.history}/>
        <Route exact path="/ssidebar" component={SsideBar} history={this.props.history}/>
        <Route exact path="/stepper/index" component={Stepper}/>
        <Route exact path="stepper/step1" component={Step1}/>
        <Route exact path="stepper/step2" component={Step2} />
        <Route exact path="stepper/step3" component={EducationDetails}/>
        <Route exact path="stepper/personal" component={PersonalDetails}/>
        <Route exact path="/jtab" component={Tas}/>
        <Route exact path="/stepper/freindex" component={Stepperf}/>
        </Switch>
        </React.Fragment>
         );
        }
      }
      
      export default App;