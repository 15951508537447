import React, { Component } from "react";
import svg from "../assets/images/svg/10.svg";
import Header from "./header";
import Banner from "./banner";
import Footer from "./footer";
import LeftSidebar from "./leftsidebar";
import RightSideBar from "./rightsidebar";
import Feature from "./feature";

class ViewJobList extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Header />
        <Banner history={this.props.history} />
        <section class="space-ptb">
          <div class="container">
            <div class="row">
              <LeftSidebar />
              <RightSideBar />
            </div>
          </div>
        </section>
        <Feature />
        <Footer />
      </React.Fragment>
    );
  }
}

export default ViewJobList;
