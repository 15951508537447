import React, { Component } from "react";
import SideBar from "./sidebar";
import Banner from "./banner";
import Header from "./header";
import Footer from "./footer";
import svg from "../assets/images/svg/10.svg";
import Sector from "../components/sector"; 
import {Link} from 'react-router-dom';

class JobList extends Component {
  state = {};
  render() {
    const dd = this.props.location.state.list.filter((list) => {
      if (list.category === this.props.location.state.cat) {
        return list;
      }
    });
    return (
      <React.Fragment>
        <Header />
        <Banner />

        <section className="space-ptb">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  {dd.map((t) => {
                    return (
                      <div className="col-md-12">
                        <div className="job-list border">
                          <div className=" job-list-logo">
                            <img className="img-fluid" src={svg} alt="" />
                          </div>
                          <div className="job-list-details">
                            <div className="job-list-info">
                              <div className="job-list-title">
                                <h5 className="mb-0">{t.jobtitle}</h5>
                              </div>
                              <div className="job-list-option">
                                <ul className="list-unstyled">
                                  <li>
                                    <i className="fas fa-map-marker-alt pr-1"></i>{" "}
                                    {t.location}{" "}
                                  </li>
                                  <li>
                                    <i className="fas fa-phone fa-flip-horizontal fa-fw"></i>
                                    <span className="pl-2">
                                      {t.contactInfo}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="job-list-favourite-time">
                            <Link className="job-list-favourite order-2" to="#">
                              <i className="far fa-heart"></i>
                            </Link>
                            <span className="job-list-time order-1">
                              <i className="far fa-clock pr-1"></i>2W ago
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="border p-4 mt-4 mt-lg-5">
                  <div className="row">
                    <div className="col-md-4 col-sm-6 mb-4">
                      <div className="d-flex">
                        <i className="font-xll text-primary align-self-center flaticon-debit-card"></i>
                        <div className="feature-info-content pl-3">
                          <label className="mb-1">Offered Salary</label>
                          <span className="mb-0 font-weight-bold d-block text-dark">
                            £15,000 - £20,000
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-4">
                      <div className="d-flex">
                        <i className="font-xll text-primary align-self-center flaticon-love"></i>
                        <div className="feature-info-content pl-3">
                          <label className="mb-1">Gender</label>
                          <span className="mb-0 font-weight-bold d-block text-dark">
                            Female
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-4">
                      <div className="d-flex">
                        <i className="font-xll text-primary align-self-center flaticon-bar-chart"></i>
                        <div className="feature-info-content pl-3">
                          <label className="mb-1">Career Level</label>
                          <span className="mb-0 font-weight-bold d-block text-dark">
                            Executive
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-md-0 mb-4">
                      <div className="d-flex">
                        <i className="font-xll text-primary align-self-center flaticon-apartment"></i>
                        <div className="feature-info-content pl-3">
                          <label className="mb-1">Industry</label>
                          <span className="mb-0 font-weight-bold d-block text-dark">
                            Management
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-sm-0 mb-4">
                      <div className="d-flex">
                        <i className="font-xll text-primary align-self-center flaticon-medal"></i>
                        <div className="feature-info-content pl-3">
                          <label className="mb-1">Experience</label>
                          <span className="mb-0 font-weight-bold d-block text-dark">
                            2 Years
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="d-flex">
                        <i className="font-xll text-primary align-self-center flaticon-mortarboard"></i>
                        <div className="feature-info-content pl-3">
                          <label className="mb-1">Qualification</label>
                          <span className="mb-0 font-weight-bold d-block text-dark">
                            Bachelor Degree
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {dd.map((i) => {
                    return (
                      <div>
                        <div className="my-4 my-lg-5">
                          <h5 className="mb-3 mb-md-4">Job Description</h5>

                          <p></p>
                          <p className="font-italic">
                            <ul>
                              <li>companyname-{i.companyname}</li>
                              <li>AboutCompany-{i.Aboutcompany}</li>
                              <li>Industry-{i.industry}</li>
                              <li>location-{i.location}</li>
                              <li>Contact-{i.contactInfo}</li>
                            </ul>
                          </p>
                        </div>
                        <hr />
                        <div className="my-4 my-lg-5">
                          <h5 className="mb-3 mb-md-4">
                            Required Knowledge, Skills, and Abilities
                          </h5>
                          <ul className="list-unstyled list-style">
                            <li>
                              <i className="far fa-check-circle font-md text-primary mr-2"></i>
                              {i.requirement}
                            </li>
                          </ul>
                        </div>
                        <hr />
                        <div className="mt-4 mt-lg-5">
                          <h5 className="mb-3 mb-md-4">JobDetails</h5>
                          <ul className="list-unstyled list-style mb-4 mb-lg-0">
                            <li>Jobtitle:{i.jobtitle}</li>
                            <li>JoiningTime:{i.joiningtime}</li>
                            <li>Requirement:{i.requirement}</li>
                            <li>Salary:{i.salary}</li>
                            <li>Skills:{i.skills}</li>
                            <li>Vacancies:{i.vaccancies}</li>
                            <li>JobDescription:{i.JobDescription}</li>
                          </ul>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* sidebar */}
              <SideBar />
              {/* sidebar end */}
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default JobList;
