import React, { Component } from "react";
import svg3 from "../assets/images/svg/03.svg";
import svg1 from "../assets/images/svg/01.svg";
import svg2 from "../assets/images/svg/02.svg";
import svg4 from "../assets/images/svg/04.svg";
import svg5 from "../assets/images/svg/05.svg";
import { getcategory, getjob } from "../services/postjobService";
import { Link } from "react-router-dom";

class RightSideBar extends Component {
  state = {
    jobs: [],
  };
  async componentDidMount() {
    const job = await getjob();
    this.setState({ jobs: job.data });
  }
  imgessvgs = { svg1, svg2, svg3, svg4, svg5 };
  render() {
    const list = this.state.jobs;
    return (
      <div className="col-lg-9">
        <div className="row mb-4">
          <div className="col-md-6">
            <div className="section-title mb-3 mb-lg-4">
              <h6 className="mb-0">
                Showing 1-5 of <span className="text-primary">28 Jobs</span>
              </h6>
            </div>
          </div>
          <div className="col-md-6">
            <div className="job-filter-tag">
              <ul className="list-unstyled">
                <li>
                  <Link to="#">
                    Freelance <i className="fas fa-times-circle"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link className="filter-clear" to="#">
                    Reset Search <i className="fas fa-redo-alt"></i>{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="job-filter mb-4 d-sm-flex align-items-center">
          <div className="job-alert-bt">
            {" "}
            <Link className="btn btn-md btn-dark" to="#">
              <i className="fa fa-envelope"></i>Get job alert{" "}
            </Link>{" "}
          </div>
          <div className="job-shortby ml-sm-auto d-flex align-items-center">
            <form className="form-inline">
              <div className="form-group mb-0">
                <label className="justify-content-start mr-2">sort by :</label>
                <div className="short-by">
                  <select className="form-control basic-select">
                    <option>Newest</option>
                    <option>Oldest</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          {list.map((i) => (
            <div className="col-12">
              <div className="job-list ">
                <div className="job-list-logo">
                  {/* {this.imagessvgs.map((i)=>{ */}
                  <img className="img-fluid" src={svg3} alt="" />
                  {/* })} */}
                </div>
                <div className="job-list-details">
                  <div className="job-list-info">
                    <div className="job-list-title">
                      <h5 className="mb-0">
                        <Link
                          to={{
                            pathname: "/tjoblist",
                            state: { i },
                          }}
                        >
                          {i.jobtitle}
                        </Link>
                      </h5>
                    </div>
                    <div className="job-list-option">
                      <ul className="list-unstyled">
                        <li>
                          {" "}
                          <span>via</span> <Link to="">{i.companyname}</Link>{" "}
                        </li>
                        <li>
                          <i className="fas fa-map-marker-alt pr-1"></i>
                          {i.location}
                        </li>

                        <li>
                          {" "}
                          <span>jobtype</span>{" "}
                          <Link to="Sector">{i.category}</Link>{" "}
                        </li>
                        <li>
                          <Link className="freelance" to="#">
                            <i className="fas fa-suitcase pr-1"></i>
                            {i.contactInfo}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="job-list-favourite-time">
                  {" "}
                  <Link className="job-list-favourite order-2" to="#">
                    <i className="far fa-heart"></i>
                  </Link>{" "}
                  <span className="job-list-time order-1">
                    <i className="far fa-clock pr-1"></i>1M ago
                  </span>{" "}
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="row">
          <div className="col-12 text-center mt-4 mt-md-5">
            <ul className="pagination justify-content-center mb-0">
              <li className="page-item disabled">
                {" "}
                <span className="page-link b-radius-none">Prev</span>{" "}
              </li>
              <li className="page-item active" aria-current="page">
                <span className="page-link">1 </span>{" "}
                <span className="sr-only">(current)</span>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  2
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  3
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  ...
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  25
                </Link>
              </li>
              <li className="page-item">
                {" "}
                <Link className="page-link" to="#">
                  Next
                </Link>{" "}
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    );
  }
}

export default RightSideBar;
