import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/applications/apply";
const apiEndpointa=apiUrl+"/applications/getap"

export function apply({email,jobId}) {
  
  return http.post(apiEndpoint, {email,jobId});
}
export function applicants({email,jobId}){
  return http.get(apiEndpoint,{email,jobId});
}
export function getdetails(jobId){
  return http.post(apiEndpointa,{jobId});
}

export default {
  apply,
  applicants,
  getdetails
}
