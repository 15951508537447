import React, { Component } from "react";
import Header from "./header";
import Joi from "joi";
import jwt from "jsonwebtoken";
import { toast } from "react-toastify";
import { eregister } from "../services/experienceService";
import {Link} from "react-router-dom";
import exploginService from "../services/exploginService";

class Experience extends Component {
  state = {
    name: "",
    email: "",
    password: "",
    contactNumber: "",
    education: {
      HighestQualifications: [
        "Doctorate/phd",
        "Masters/Post-Graduation",
        "Graduation/Diploma",
        "Inter",
        "SSC",
      ],
      Course: [
        "B.Tech/B.E",
        "B.sc",
        "B.Com",
        "B.A",
        "B.Arch",
        "B.Pharm",
        "B.B.A",
        "M.B.B.S",
        "B.Ed",
        "Diploma",
        "M.Tech",
        "M.Pharm",
      ],
      Specialization: [
        "CSE",
        "ECE",
        "EEE",
        "MECHANICAL",
        "IT",
        "CIVIL",
        "Agriculture",
        "Automobile",
        "Chemical",
        "Bio-medical",
        "others",
      ],
      College: "",
      coursetype: "",
      passingyear: [
        "2002",
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
      ],
    },
    employement: {
      totalworkexperience: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
      ],
      resume: "",
      CurrentDesignation: "",
      CurrentCompany: "",
      AnnualSalary: [
        "1Lakh",
        "2Lakhs",
        "3Lakhs",
        "4Lakhs",
        "5Lakhs",
        "6Lakhs",
        "7Lakhs",
        "8Lakhs",
        "9Lakhs",
        "10Lakhs",
        "11Lakhs",
        "12Lakhs",
        "13Lakhs",
        "14Lakhs",
        "15Lakhs",
        "16Lakhs",
        "17Lakhs",
        "18Lakhs",
        "19Lakhs",
        "20Lakhs",
        "21Lakhs",
        "22Lakhs",
      ],
      Workingsince: {
        Year: [
          "Year",
          "2000",
          "2001",
          "2002",
          "2003",
          "2004",
          "2005",
          "2006",
          "2007",
          "2008",
          "2009",
          "2010",
          "2011",
          "2012",
          "2013",
          "2014",
          "2015",
          "2016",
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023",
          "2024",
        ],
        Months: [
          "Month",
          "Jan",
          "feb",
          "mar",
          "apr",
          "may",
          "june",
          "july",
          "aug",
          "sep",
          "oct",
          "nov",
          "dec",
        ],
        present: [
          "Present",
          "2000",
          "2001",
          "2002",
          "2003",
          "2004",
          "2005",
          "2006",
          "2007",
          "2008",
          "2009",
          "2010",
          "2011",
          "2012",
          "2013",
          "2014",
          "2015",
          "2016",
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023",
          "2024",
        ],
        Month: [
          "month",
          "Jan",
          "feb",
          "mar",
          "apr",
          "may",
          "june",
          "july",
          "aug",
          "sep",
          "oct",
          "nov",
          "dec",
        ],
      },
      CurrentCity: "",
      DurationofNoticePeriod: [
        "Select duration of your Notice Period",
        "15 Days or less",
        "1 Month",
        "2 Months",
        "3 Months",
        "More than 3Months",
      ],
      Skills: "",
      Industries: [
        "Select industry that you work for",
        "Accounting/Finance",
        "Asvertising/PR/MR/Events",
        "Agriculture/Dairy",
        "Animation",
        "Architecture/Intirior Design",
        "Banking/Financial Services",
        "BPO/ITES",
        "Broadcasting",
        "Chemical",
        "Glass",
        "Hotels",
        "IT-Hardware&Networking",
        "IT-Software&Software Services",
        "Other",
      ],
      FunctionalArea: [
        "Select the Department That You Work in",
        "Accounts/Finance/Tax/Cs/Audit",
        "Agent",
        "Analytics&Business Intiligent",
        "Banking/Insurence",
        "Beauty/Fittness/Spa Services",
        "Content/Journalisum",
        "Corporate Planing/Consulting",
        "Engineering Design/R&D",
        "H.R/Administration/IR",
        " IT Hareware/Telicom/Technical Staff/Support",
        "IT Sopftware-DBA/ Datawarehousing",
        "It-Software/Mobile",
        "More",
      ],
      Role: [
        "Select the Role That You Work in",
        "Programming/Design",
        "Software Devloper",
        "Team Lead/Tech Lead",
        "System Analist",
        "Tech architect",
        "Database Architect/Designer",
        "Project Lead",
        "Testing Engineer",
        "Product Manager",
        "Graphic/Web Designer",
        "Network Admin",
        "DBA",
        "System Admin",
        "Tech Support Engineer",
        "Project Management",
        "Edp analyst",
        "Functional Outside Technician",
      ],
    },
    Describe: {
      describe: "",
      PreferredWorkLocation: "",
      JobType: "",
      EmployementType: "",
      ITSKILLS: "",
      Gender: ["Male", "Female", "Transgender"],
    },
    var: "",
    temp: "",
    exp: "",
    expbtn:"false"
  };
  schema = {
    name: Joi.string().required().label("Name"),
    email: Joi.string().required().label("Email"),
    password: Joi.string().required().label("Password"),
    contactNumber: Joi.number().required().label("Mobile Number"),
    employement: Joi.string().required().label("employement"),
    education: Joi.string().required().label("education"),
    describe: Joi.string().required().label("describe"),
  };
  handlehighestqualifications = (e) => {
    //console.log(e.target.value);
    if (e.target.value) {
      this.setState({ HighestQualifications: e.target.value });
    }
  };
  handleyear = (e) => {
    //console.log(e.target.value);
    if (e.target.value) {
      this.setState({ Year: e.target.value });
    }
  };
  handlemonths = (e) => {
    //console.log(e.target.value);
    if (e.target.value) {
      this.setState({ Months: e.target.value });
    }
  };
  handlepresentyear = (e) => {
    //console.log(e.target.value);
    if (e.target.value) {
      this.setState({ present: e.target.value });
    }
  };
  handleMonth = (e) => {
    //console.log(e.target.value);
    if (e.target.value) {
      this.setState({ Month: e.target.value });
    }
  };
  handletotalwork = (e) => {
    //console.log(e.target.value);
    if (e.target.value) {
      this.setState({ totalworkexperience: e.target.value });
    }
  };
  handleannualsalary = (e) => {
    //console.log(e.target.value);
    if (e.target.value) {
      this.setState({ AnnualSalary: e.target.value });
    }
  };
  handleCourses = (e) => {
    //console.log(e.target.value);
    if (e.target.value) {
      this.setState({ Course: e.target.value });
    }
  };
  handleSpecializations = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      this.setState({ Specialization: e.target.value });
    }
  };
  handleyears = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      this.setState({ passingyear: e.target.value });
    }
  };
  handleDurations = (e) => {
    //console.log(e.target.value);
    if (e.target.value) {
      this.setState({ DurationofNoticePeriod: e.target.value });
    }
  };
  handleindustries = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      this.setState({ Industries: e.target.value });
    }
  };
  handlefunctionalareas = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      this.setState({ FunctionalArea: e.target.value });
    }
  };
  handleroles = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      this.setState({ Role: e.target.value });
    }
  };
  handlegender = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      this.setState({ Gender: e.target.value });
    }
  };

  handleSubmit = async () => {
    await this.setState({expbtn:"true"})
    if (this.state) {
    }
    try {
      const data = {
        name: this.state.name,
        contactNumber: this.state.contactNumber,
        password: this.state.password,
        email: this.state.email,
        employement: this.state.employement,
        education: this.state.education,
        describe: this.state.describe,
      };

      const response = await eregister(data);
      // auth.emploginWithJwt(response.headers["x-auth-token"]);
      // localStorage.setItem("token", jwt);
      if(!response){
        toast.error("fill all details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      else{
      toast.success("successfully register", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setTimeout(() => {
        window.location = "/";
      }, 5000);
    }
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  radiohandleChange = async (value) => {
    await this.setState({ var: value });
    console.log(this.state.var);
  };

  radiohandleChange1 = async (value) => {
    await this.setState({ temp: value });
    console.log(this.state.temp);
  };

  radiohandleChange3 = async (value) => {
    await this.setState({ exp: value });
    console.log(this.state.exp);
  };

  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="space-ptb bg-light">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="section-title text-center">
                  <h2 className="text-primary">Register for Experience</h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <form>
            <div className="row">
              <div className="form-group col-sm-3">
                <label for="exampleInputPassword1">Name *</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputName"
                  placeholder="Name"
                  value={this.state.name}
                  onChange={(name) =>
                    this.setState({ name: name.target.value })
                  }
                />
              </div>

              <div className="form-group col-sm-3">
                <label for="exampleInputEmail1">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={(email) =>
                    this.setState({ email: email.target.value })
                  }
                />
              </div>

              <div className="form-group col-sm-3">
                <label for="exampleInputPassword1">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Minimum 8 characters"
                  value={this.state.password}
                  onChange={(password) =>
                    this.setState({ password: password.target.value })
                  }
                />
              </div>

              <div className="form-group col-sm-3">
                <label for="exampleInputPassword1">Mobile Number *</label>
                <input
                  type="Mobile Number"
                  className="form-control"
                  placeholder="Please enter your mobile number"
                  value={this.state.contactNumber}
                  onChange={(contactNumber) =>
                    this.setState({ contactNumber: contactNumber.target.value })
                  }
                />
              </div>

              <div className="form-group col-sm-3">
                <label>Total Work Experience *</label>
                <select
                  className="form-control"
                  onChange={(e) => this.handletotalwork(e)}
                >
                  {this.state.employement.totalworkexperience.map((i) => (
                    <option value={i}>{i}</option>
                  ))}
                </select>
              </div>

              <div className="form-group col-sm-3">
                <label>Upload Resume *</label>
                <input
                  type="file"
                  className="form-control"
                  value={this.state.resume}
                  onChange={(resume) =>
                    this.setState({ resume: resume.target.value })
                  }
                  placeholder="Please choose your Resume"
                />
              </div>

              <div className="form-group col-sm-3">
                <label for="exampleInputEmail1">Current Designation *</label>
                <input
                  type="Current Designation"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Your Current Designation"
                  value={this.state.CurrentDesignation}
                  onChange={(CurrentDesignation) =>
                    this.setState({
                      CurrentDesignation: CurrentDesignation.target.value,
                    })
                  }
                />
              </div>

              <div className="form-group col-sm-3">
                <label for="exampleInputEmail1">Current Company *</label>
                <input
                  type="Current Company "
                  className="form-control"
                  placeholder="Where you are Currently Working "
                  value={this.state.CurrentCompany}
                  onChange={(CurrentCompany) =>
                    this.setState({
                      CurrentCompany: CurrentCompany.target.value,
                    })
                  }
                />
              </div>

              <div className="form-group col-sm-3">
                <label>Annual Salary *</label>
                <select
                  className="form-control"
                  onChange={(e) => this.handleannualsalary(e)}
                >
                  {this.state.employement.AnnualSalary.map((i) => (
                    <option value={i}>{i}</option>
                  ))}
                </select>

                {/* <select className="form-control basic-select">
                    <option value="value 01">10000</option>
                    <option value="value 02">25000</option>
                    <option value="value 03">35000</option>
                  </select>  */}
              </div>

              <div className="form-group col-sm-6">
                <label>Working since *</label>
                <div className="form-group col-sm-6 d-flex select-border">
                  <select
                    className="form-control basic-select col-md-6"
                    onChange={(e) => this.handleyear(e)}
                  >
                    {this.state.employement.Workingsince.Year.map((i) => (
                      <option value={i}>{i}</option>
                    ))}
                  </select>

                  <select
                    className="form-control basic-select col-md-6 "
                    onChange={(e) => this.handlemonths(e)}
                  >
                    {this.state.employement.Workingsince.Months.map((i) => (
                      <option value={i}>{i}</option>
                    ))}
                  </select>

                  <select
                    className="form-control basic-select col-md-6"
                    onChange={(e) => this.handlepresentyear(e)}
                  >
                    {this.state.employement.Workingsince.present.map((i) => (
                      <option value={i}>{i}</option>
                    ))}
                  </select>

                  <select
                    className="form-control basic-select col-md-6"
                    onChange={(e) => this.handleMonth(e)}
                  >
                    {this.state.employement.Workingsince.Month.map((i) => (
                      <option value={i}>{i}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-3">
                <label for="exampleInputEmail1">Current City *</label>
                <input
                  type="Current City"
                  className="form-control"
                  placeholder="Tell us about your Current city"
                  value={this.state.currentcity}
                  onChange={(currentcity) =>
                    this.setState({ currentcity: currentcity.target.value })
                  }
                />
              </div>

              <div className="form-group col-sm-3">
                <label>Duration of Notice Period *</label>
                <select
                  className="form-control"
                  onChange={(e) => this.handleDurations(e)}
                >
                  {this.state.employement.DurationofNoticePeriod.map((i) => (
                    <option value={i}>{i}</option>
                  ))}
                </select>
              </div>

              {/* <div className="form-group col-sm-3">
                <label for="exampleInputEmail1">Skills *</label>
                <input
                  type="Current City"
                  className="form-control"
                  placeholder="Where you are Currently Working"
                  value={this.state.employement.Skills}
                  onChange={(Skills) =>
                    this.setState({ Skills: Skills.target.value })
                  }
                />
              </div> */}

              <div className="form-group col-sm-3">
                <label>Industry *</label>
                <select
                  className="form-control"
                  onChange={(e) => this.handleindustries(e)}
                >
                  {this.state.employement.Industries.map((i) => (
                    <option value={i}>{i}</option>
                  ))}
                </select>
              </div>

              <div className="form-group col-sm-3">
                <label>Functional Area *</label>
                <select
                  className="form-control"
                  onChange={(e) => this.handlefunctionalareas(e)}
                >
                  {this.state.employement.FunctionalArea.map((i) => (
                    <option value={i}>{i}</option>
                  ))}
                </select>
              </div>

              <div className="form-group col-sm-3">
                <label>Role *</label>
                <select
                  className="form-control"
                  onChange={(e) => this.handleroles(e)}
                >
                  {this.state.employement.Role.map((i) => (
                    <option value={i}>{i}</option>
                  ))}
                </select>
              </div>

              <div className="form-group col-sm-3 select-border">
                <label>Highest Qualification *</label>
                <select
                  className="form-control"
                  onChange={(e) => this.handlehighestqualifications(e)}
                >
                  {this.state.education.HighestQualifications.map((i) => (
                    <option value={i}>{i}</option>
                  ))}
                </select>
              </div>

              <div className="form-group col-sm-3 select-border">
                <label>Course *</label>
                <select
                  className="form-control"
                  onChange={(e) => this.handleCourses(e)}
                >
                  {this.state.education.Course.map((i) => (
                    <option value={i}>{i}</option>
                  ))}
                </select>
              </div>

              <div className="form-group col-sm-3 select-border">
                <label>Specialization *</label>
                <select
                  className="form-control"
                  onChange={(e) => this.handleSpecializations(e)}
                >
                  {this.state.education.Specialization.map((i) => (
                    <option value={i}>{i}</option>
                  ))}
                </select>
              </div>

              <div className="form-group col-sm-3">
                <label for="exampleInputEmail1">University /College *</label>
                <input
                  type="University /College "
                  className="form-control"
                  placeholder="Institute Name"
                  value={this.state.College}
                  onChange={(College) =>
                    this.setState({ College: College.target.value })
                  }
                />
              </div>
              <div
                className="col-sm-3"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <label>Course Type *</label>
                <div className="row">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value={this.state.var}
                      onChange={() => this.radiohandleChange("Fulltime")}
                    />
                    <label class="form-check-label " for="inlineRadio1">
                      Full Time
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="var"
                      onChange={() => this.radiohandleChange("Parttime")}
                    />
                    <label class="form-check-label" for="inlineRadio2">
                      Part Time
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio3"
                      value="var"
                      onChange={() => this.radiohandleChange("correspondence")}
                    />
                    <label class="form-check-label" for="inlineRadio3">
                      Correspondence
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group col-sm-3 select-border">
                <label>Passing Year *</label>
                <select
                  className="form-control"
                  onChange={(e) => this.handleyears(e)}
                >
                  {this.state.education.passingyear.map((i) => (
                    <option value={i}>{i}</option>
                  ))}
                </select>
              </div>

              <div className="form-group col-sm-3">
                <label for="exampleInputEmail1">Skills *</label>
                <input
                  type="Skills"
                  className="form-control"
                  placeholder="Enter your areas of expertise/Specialization"
                  value={this.state.Skills}
                  onChange={(Skills) =>
                    this.setState({ Skills: Skills.target.value })
                  }
                />
              </div>

              <div className="form-group col-sm-3">
                <label for="exampleInputEmail1">
                  Describe your professional background in one line *
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Jobseeker  with B.Tech/B.E. in Computers currently living in Delhi"
                  value={this.state.describe}
                  onChange={(describe) =>
                    this.setState({ describe: describe.target.value })
                  }
                />
              </div>

              <div className="form-group col-sm-3">
                <label for="exampleInputEmail1">
                  Preferred Work Location *
                </label>
                <input
                  type="Preferred Work Location"
                  className="form-control"
                  placeholder="Select Location"
                  value={this.state.PreferredWorkLocation}
                  onChange={(PreferredWorkLocation) =>
                    this.setState({
                      PreferredWorkLocation: PreferredWorkLocation.target.value,
                    })
                  }
                />
              </div>

              <div
                className="col-sm-3"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <label>Job Type *</label>
                <div className="row">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOption"
                      id="inlineRadio"
                      value={this.state.temp}
                      onChange={() => this.radiohandleChange1("Perminent")}
                    />
                    <label class="form-check-label " for="inlineRadio1">
                      Perminent
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOption"
                      id="inlineRadio"
                      value={this.state.temp}
                      onChange={() => this.radiohandleChange1("Temporary")}
                    />
                    <label class="form-check-label" for="inlineRadio2">
                      Temporary
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOption"
                      id="inlineRadio"
                      value={this.state.temp}
                      onChange={() => this.radiohandleChange1("Both")}
                    />
                    <label class="form-check-label" for="inlineRadio3">
                      Both
                    </label>
                  </div>
                </div>
              </div>

              <div
                className="col-sm-3"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <label>Employement Type *</label>
                <div className="row">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOption3"
                      id="inlineRadio3"
                      value={this.state.exp}
                      onChange={() => this.radiohandleChange3("Fulltime")}
                    />
                    <label class="form-check-label " for="inlineRadio1">
                      Fulltime
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOption3"
                      id="inlineRadio3"
                      value={this.state.exp}
                      onChange={() => this.radiohandleChange3("Parttime")}
                    />
                    <label class="form-check-label" for="inlineRadio2">
                      Parttime
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOption3"
                      id="inlineRadio3"
                      value={this.state.exp}
                      onChange={() => this.radiohandleChange3("Both")}
                    />
                    <label class="form-check-label" for="inlineRadio3">
                      Both
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group col-sm-3">
                <label for="exampleInputEmail1">IT Skills *</label>
                <input
                  type="IT Skills"
                  className="form-control"
                  placeholder="Please enter your Skills"
                  value={this.state.ITSKILLS}
                  onChange={(ITSKILLS) =>
                    this.setState({ ITSKILLS: ITSKILLS.target.value })
                  }
                />
              </div>

              <div className="form-group col-sm-3 select-border">
                <label>Gender *</label>
                <select
                  className="form-control"
                  onChange={(e) => this.handlegender(e)}
                >
                  {this.state.Describe.Gender.map((i) => (
                    <option value={i}>{i}</option>
                  ))}
                </select>
              </div>
              <div className="form-group col-sm-3">
                <button
                  type="button"
                  disabled={this.state.expbtn}
                  className="btn btn-primary"
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default Experience;
