import React, { Component } from "react";
import avatar from "../../assets/images/avatar/04.jpg";
import {Link} from "react-router-dom";
class PBanner extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="header-inner bg-light">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="candidates-user-info">
                  <div className="jobber-user-info">
                    <div className="profile-avatar">
                      <img className="img-fluid " src={avatar} alt="" />
                      <i className="fas fa-pencil-alt"></i>
                    </div>
                    <div className="profile-avatar-info ml-4">
                      <h3>Felica Queen</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "85%" }}
                  >
                    <span className="progress-bar-number">85%</span>
                  </div>
                </div>
                <div className="candidates-skills">
                  <div className="candidates-skills-info">
                    <h3 className="text-primary">85%</h3>
                    <span className="d-block">
                      Skills increased by job Title.
                    </span>
                  </div>
                  <div className="candidates-required-skills ml-auto mt-sm-0 mt-3">
                    <Link className="btn btn-dark" to="#">
                      Complete Required Skills
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PBanner;
