import React, { Component } from 'react';
import {Link} from "react-router-dom";
import svg from "../assets/images/svg/01.svg"
import Header from './header';
import Footer from './footer';

class Search extends Component {
    state = {  }
    render() { 
        const ds=this.props.location.state;
        console.log(ds);
        return ( 
            <React.Fragment>
                <Header/>
            <section className="space-ptb">
              <div className="container">
                <div className="row">
                  <div className="col-12">

                    <div className="section-title mb-0">
                      <h2 className="title">Jobs You May be Interested in</h2>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row mt-3">
                     
                        {ds.map((key,i)=>{
                            return(
                        <div className="col-lg-6 mb-4 mb-sm-0">
                          <div className="job-list">
                            <div className="job-list-logo">
                              <img className="img-fluid" src={svg} alt="" />
                            </div>
    
                            <div className="job-list-details">
                              <div className="job-list-info">
                                <div className="job-list-title">
                                  <h5 className="mb-0">
                                    <Link
                                     to={{ pathname: "/sjoblist", state: { key} }}
                                    >
                                      {" "}
                                      {key.jobtitle}
                                    
                                    </Link>
                                  </h5>
                                </div>
                                <div className="job-list-option">
                                  <ul className="list-unstyled">
                                    <li>
                                      <span>via</span>
                                      <Link to="">
                                           {key.companyname} 
                                          </Link>
                                    </li>
                                    <li>
                                      <i className="fas fa-map-marker-alt pr-1"></i>
                                       {key.location} 
                                    </li>
                                    <li>
                                      <i className="fas fa-filter pr-1"></i>
                                       {key.category} 
                                    </li>
                                    <li>
                                      <Link className="freelance" to="#">
                                        <i className="fas fa-suitcase pr-1"></i>
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
    
                            <div className="job-list-favourite-time">
                              <Link className="job-list-favourite order-2" to="">
                                <i className="far fa-heart"></i>
                              </Link>
                              <span className="job-list-time order-1">
                                <i className="far fa-clock pr-1"></i>1H ago
                              </span>
                            </div>
                          </div>
                        </div>)
                    
                        })}
                      
                    </div>
    
                    
                  </div>
                </div>
              </div>
            </section>
            <Footer/>
          </React.Fragment>
         );
    }
}
 
export default Search;