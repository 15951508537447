import React, { Component } from "react";
import Header from "./header";
import Joi from "joi-browser";
import Input from "./common/input";
import Form from "./common/form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import employerService from "../services/employerService";
import auth, { emploginWithJwt } from "../services/employerloginService";

class Employer extends Component {
  state = {
    name: "",
    employeremail: "",
    password: "",
    contactNumber: "",
    industry: "",
    companyname: "",
    location: "",
    industries: [
      "Select",
      "Human Resources",
      "Energy",
      "IT-Telecome",
      "Software",
      "Hardware",
      "Marketing",
    ],
    btn_dis:false
  };

  schema = {
    name: Joi.string().required().label("Name"),
    employeremail: Joi.string().required().label("Email"),
    password: Joi.string().required().label("Password"),
    contactNumber: Joi.number().required().label("Mobile Number"),
    industry: Joi.string().required().label("Industry"),
    companyname: Joi.string().required().label("CompanyName"),
    location: Joi.string().required().label("Location"),
  };

  handleindustries = (e) => {
    if (e.target.value) {
      this.setState({ industry: e.target.value });
    }
  };

  doSubmit = async () => {
    await this.setState({ btn_dis:true})
    if (this.state) {
    }
    try {
      const data = {
        name: this.state.name,
        contactNumber: this.state.contactNumber,
        password: this.state.password,
        employeremail: this.state.employeremail,
        industry: this.state.industry,
        companyname: this.state.companyname,
        location: this.state.location,
      };
      const response = await employerService.empregister(data);

      // auth.loginWithJwt(response.headers['x-auth-token']);
      if (!response) {
        toast.error("fill all details correctly", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success(response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });

        setTimeout(() => {
          window.location = "/";
        }, 5000);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <React.Fragment>
        <Header />
        <section class="space-ptb bg-light">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12">
                <div class="section-title text-center">
                  <h2 class="text-primary">Employer Register Form</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="container">
          <div class="row">
            <Input
              type="text"
              class="form-control"
              id="exampleInputName"
              placeholder="Enter your Name"
              label="Name"
              value={this.state.name}
              onChange={(name) => this.setState({ name: name.target.value })}
              // error={errors.name}
            />

            <Input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Your Email"
              label="Email"
              value={this.state.employeremail}
              onChange={(email) =>
                this.setState({ employeremail: email.target.value })
              }
              // error={errors.email}
            />

            <Input
              type="password"
              placeholder="Enter Password"
              label="Password"
              value={this.state.password}
              onChange={(password) =>
                this.setState({ password: password.target.value })
              }
              // error={errors.password}
            />

            <Input
              placeholder="Please enter your Contact Number"
              label="ContactNumber"
              value={this.state.contactNumber}
              onChange={(contactNumber) =>
                this.setState({ contactNumber: contactNumber.target.value })
              }
              // error={errors.contactNumber}
            />

            <div class="form-group col-md-6 mb-md-0 select-border">
              <label>Industry *</label>
              <select
                className="form-control"
                onChange={(e) => this.handleindustries(e)}
              >
                {this.state.industries.map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>
            </div>

            <Input
              type="CompanyName"
              class="form-control"
              id="exampleInputName"
              placeholder="Enter your Company Name"
              label="CompanyName"
              value={this.state.companyname}
              onChange={(companyname) =>
                this.setState({ companyname: companyname.target.value })
              }
              // error={errors.companyname}
            />

            <Input
              type="Location"
              class="form-control"
              id="exampleInputName"
              placeholder="Enter your Location"
              label="Location"
              value={this.state.Location}
              onChange={(location) =>
                this.setState({ location: location.target.value })
              }
            />

            <div class="form-group col-md-12">
              <button disabled={this.state.btn_dis} onClick={this.doSubmit} class="btn btn-primary btn-md">
                Submit
              </button>
            </div>
          </div>
        </div>{" "}
      </React.Fragment>
    );
  }
}

export default Employer;
