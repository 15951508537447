import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Header from '../components/header';

class Feature extends Component {
  state = {};
  render() {
    return (
      <section className="feature-info-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <div className="feature-info feature-info-02 p-4 p-lg-5 bg-primary">
                <div className="feature-info-icon mb-3 mb-sm-0 text-dark">
                  <i className="flaticon-team"></i>
                </div>
                <div className="feature-info-content text-white pl-sm-4 pl-0">
                  <p>Jobseeker</p>
                  <h5 className="text-white">Looking For Job?</h5>
                </div>
                <Link className="ml-auto align-self-center"  to="">
                  Apply now<i className="fas fa-long-arrow-alt-right"></i>{" "}
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="feature-info feature-info-02 p-4 p-lg-5 bg-dark">
                <div className="feature-info-icon mb-3 mb-sm-0 text-primary">
                  <i className="flaticon-job-3"></i>
                </div>
                <div className="feature-info-content text-white pl-sm-4 pl-0">
                  <p>Recruiter</p>
                  <h5 className="text-white">Are You Recruiting?</h5>
                </div>
                <Link className="ml-auto align-self-center" to="" onClick={this.fun}>
                  Post a job<i className="fas fa-long-arrow-alt-right"></i>{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Feature;
