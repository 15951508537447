import React, { Component } from 'react';


class Step2 extends Component {
    state = { 
      employement:{
        designation:"",
      companyname:" ",
      annualsalary:"",
      workingsince:"",
      cityworkedin:"",
      noticeperiod:"",
      employementtype:"",
      describeyourrole:""
      },
      var: "",
    temp: "",
    exp: "",
      
     }
     radiohandleChange3 = async (value) => {
      await this.setState({ exp: value });
      console.log(this.state.exp);
    };
    render() { 
        return ( 
            <React.Fragment>
                <div>
                    
                    <div className="row">
                    <div className="form-group col-md-4">
              <label for="exampleInput">Designation*</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your Designation"
                value={this.state.designation}
                onChange={(designation) => this.setState({ designation: designation.target.value })}
              />
            </div>
            <div className="form-group col-md-4">
              <label for="exampleInput">Company Name*</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your companyname"
                value={this.state.companyname}
                onChange={(companyname) => this.setState({ companyname: companyname.target.value })}
              />
            </div>
            <div className="form-group col-md-4">
              <label for="exampleInputPassword1">Annual Salary*</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your annualsalary"
                value={this.state.annualsalary}
                onChange={(annualsalary) => this.setState({ annualsalary: annualsalary.target.value })}
              />
            </div>
            <div className="form-group col-md-4">
              <label for="exampleInputPassword1">Working Since*</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your workingsince"
                value={this.state.workingsince}
                onChange={(workingsince) => this.setState({ workingsince: workingsince.target.value })}
              />
            </div>
            <div className="form-group col-md-4">
              <label for="exampleInputPassword1">City WorkedIn</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your city worked in"
                value={this.state.cityworkedin}
                onChange={(cityworkedin) => this.setState({ cityworkedin: cityworkedin.target.value })}
              />
            </div>
            <div className="form-group col-md-4"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <label>Employement Type *</label>
              <div className="row">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOption3"
                    id="inlineRadio3"
                    value={this.state.exp}
                    onChange={() => this.radiohandleChange3("Fulltime")}
                  />
                  <label class="form-check-label " for="inlineRadio1">
                    Fulltime
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOption3"
                    id="inlineRadio3"
                    value={this.state.exp}
                    onChange={() => this.radiohandleChange3("Parttime")}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Parttime
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOption3"
                    id="inlineRadio3"
                    value={this.state.exp}
                    onChange={() => this.radiohandleChange3("Both")}
                  />
                  <label class="form-check-label" for="inlineRadio3">
                    Both
                  </label>
                </div>
              </div>
            </div>
            
            <div className="form-group col-md-4">
              <label for="exampleInputPassword1">Notice Period</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your notice period"
                // value={this.state.noticeperiod}
                // onChange={(noticeperiod) => this.setState({ noticeperiod: noticeperiod.target.value })}
              />
            </div>
            <div className="form-group col-md-4">
              <label for="exampleInputPassword1">Describe Your Role</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your Describe your Role"
                // value={this.state.Describeyourrole}
                // onChange={(Describeyourrole) => this.setState({ Describeyourrole: Describeyourrole.target.value })}
              />
            </div>
            </div>
                    
                </div>
            </React.Fragment>
         );
    }
}
 
export default Step2;