import http from "./httpService";
import { apiUrl} from '../config.json';

const apiEndpoint = apiUrl +"/employers/job";

export function jobpost(job){

    return http.post(apiEndpoint,{
        employeremail:job.employeremail,
        companyname:job.companyname,
        location:job.location, 
        Aboutcompany:job.Aboutcompany,
        JobDescription:job.JobDescription,
        industry:job.industry,
        contactInfo:job.contactInfo,
        requirement:job.requirement,
        salary:job.salary,
        joiningtime:job.joiningtime,
        skills:job.skills,
        vaccancies:job.vaccancies,
        jobtitle:job.jobtitle,
        category:job.category,  
    })
   
}
// export function getjob(){
//     return http.get(`${apiEndpoint}/${job}`);
// }
export function getjob(){
    return http.get(apiEndpoint)
}
export function getcategory(category){
    return http.post(`${apiEndpoint}/${category}`);
}

export default {
    jobpost,
    getjob
}
    