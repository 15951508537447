import React, { Component } from "react";
import { Link } from "react-router-dom";

class Dashboard extends Component {
  state = {};
  render() {
    return (
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sticky-top secondary-menu-sticky-top">
                <div className="secondary-menu">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <Link to="CandidateDashboard">Dashboard</Link>
                    </li>
                    <li>
                      <Link to="Profile">My Profile</Link>
                    </li>
                    <li>
                      <Link to="ChangePassword">Change Password</Link>
                    </li>
                    <li>
                      <Link to="">Saved Jobs</Link>
                    </li>
                    <li>
                      <Link to="">Log Out</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Dashboard;
