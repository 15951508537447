import React, { Component } from "react";
import svg from "../assets/images/svg/10.svg";
import svg9 from "../assets/images/svg/09.svg";
import svg6 from "../assets/images/svg/06.svg";
import svg17 from "../assets/images/svg/17.svg";
import svg18 from "../assets/images/svg/18.svg";
import svg19 from "../assets/images/svg/19.svg";
import { Link } from "react-router-dom";
import postjobService from "../services/postjobService";
import Header from "./header";
import Banner from "./banner";
import Sector from "./sector";
import Footer from "./footer";


class Jobs extends Component {
  state = {
    alljobs: [],
  };
  async componentDidMount() {
    
    const job = await postjobService.getjob();
    this.setState({ alljobs: job.data });
  }

  render() {
    const list = this.state.alljobs;

    return (
      <React.Fragment>
        <section className="space-ptb">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title mb-0">
                  <h2 className="title">Jobs You May be Interested in</h2>
                </div>
              </div>
              <div className="col-12">
                <div className="row mt-3">
                  {list.map((i) => (
                    <div className="col-lg-6 mb-4 mb-sm-0">
                      <div className="job-list">
                        <div className="job-list-logo">
                          <img className="img-fluid" src={svg} alt="" />
                        </div>

                        <div className="job-list-details">
                          <div className="job-list-info">
                            <div className="job-list-title">
                              <h5 className="mb-0">
                                <Link
                                  to={{ pathname: "/tjoblist", state: { i } }}
                                >
                                  {" "}
                                  {i.jobtitle}
                                </Link>
                              </h5>
                            </div>
                            <div className="job-list-option">
                              <ul className="list-unstyled">
                                <li>
                                  <span>via</span>
                                  <Link to="">{i.companyname}</Link>
                                </li>
                                <li>
                                  <i className="fas fa-map-marker-alt pr-1"></i>
                                  {i.location}
                                </li>
                                <li>
                                  <i className="fas fa-filter pr-1"></i>
                                  {i.category}
                                </li>
                                <li>
                                  <Link className="" >
                                    <i className="fas fa-suitcase pr-1"></i>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div className="job-list-favourite-time">
                          <Link className="job-list-favourite order-2" >
                            <i className="far fa-heart"></i>
                          </Link>
                          <span className="job-list-time order-1">
                            <i className="far fa-clock pr-1"></i>1H ago
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* <div className="col-lg-6 mb-4 mb-sm-0">
                  <div className="job-list">
                    <div className="job-list-logo">
                      <img className="img-fluid" src={svg9} alt="" />
                    </div>
                    <div className="job-list-details">
                      <div className="job-list-info">
                        <div className="job-list-title">
                          <h5 className="mb-0">
                            <Link to="">Web Developer – .net</Link>
                          </h5>
                        </div>
                        <div className="job-list-option">
                          <ul className="list-unstyled">
                            <li>
                              <span>via</span>
                              <Link to="">Pendragon Green Ltd</Link>
                            </li>
                            <li>
                              <i className="fas fa-map-marker-alt pr-1"></i>
                              Needham, MA
                            </li>
                            <li>
                              <i className="fas fa-filter pr-1"></i>IT &
                              Telecoms
                            </li>
                            <li>
                              <Link className="part-time" to="#">
                                <i className="fas fa-suitcase pr-1"></i>
                                Part-Time
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="job-list-favourite-time">
                      <Link className="job-list-favourite order-2" to="#">
                        <i className="far fa-heart"></i>
                      </Link>
                      <span className="job-list-time order-1">
                        <i className="far fa-clock pr-1"></i>3D ago
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4 mb-sm-0">
                  <div className="job-list">
                    <div className=" job-list-logo">
                      <img className="img-fluid" src={svg6} alt="" />
                    </div>
                    <div className="job-list-details">
                      <div className="job-list-info">
                        <div className="job-list-title">
                          <h5 className="mb-0">
                            <Link to="">Group Marketing Manager</Link>
                          </h5>
                        </div>
                        <div className="job-list-option">
                          <ul className="list-unstyled">
                            <li>
                              <span>via</span>
                              <Link to="">Wight Sound Hearing LLC</Link>
                            </li>
                            <li>
                              <i className="fas fa-map-marker-alt pr-1"></i>New
                              Castle, PA
                            </li>
                            <li>
                              <i className="fas fa-filter pr-1"></i>Banking
                            </li>
                            <li>
                              <Link className="temporary" to="#">
                                <i className="fas fa-suitcase pr-1"></i>
                                Temporary
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="job-list-favourite-time">
                      <Link className="job-list-favourite order-2" to="#">
                        <i className="far fa-heart"></i>
                      </Link>
                      <span className="job-list-time order-1">
                        <i className="far fa-clock pr-1"></i>2W ago
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4 mb-sm-0">
                  <div className="job-list">
                    <div className="job-list-logo">
                      <img className="img-fluid" src={svg17} alt="" />
                    </div>
                    <div className="job-list-details">
                      <div className="job-list-info">
                        <div className="job-list-title">
                          <h5 className="mb-0">
                            <Link to="">Data Entry Administrator</Link>
                          </h5>
                        </div>
                        <div className="job-list-option">
                          <ul className="list-unstyled">
                            <li>
                              <span>via</span>
                              <Link to="">Tan Electrics Ltd</Link>
                            </li>
                            <li>
                              <i className="fas fa-map-marker-alt pr-1"></i>Park
                              Avenue, Mumbai
                            </li>
                            <li>
                              <i className="fas fa-filter pr-1"></i>Charity &
                              Voluntary
                            </li>
                            <li>
                              <Link className="full-time" to="#">
                                <i className="fas fa-suitcase pr-1"></i>
                                Full-time
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="job-list-favourite-time">
                      <Link className="job-list-favourite order-2" to="#">
                        <i className="far fa-heart"></i>
                      </Link>
                      <span className="job-list-time order-1">
                        <i className="far fa-clock pr-1"></i>3M ago
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4 mb-sm-0">
                  <div className="job-list">
                    <div className="job-list-logo">
                      <img className="img-fluid" src={svg18} alt="" />
                    </div>
                    <div className="job-list-details">
                      <div className="job-list-info">
                        <div className="job-list-title">
                          <h5 className="mb-0">
                            <Link to="">Stockroom Assistant</Link>
                          </h5>
                        </div>
                        <div className="job-list-option">
                          <ul className="list-unstyled">
                            <li>
                              <span>via</span>
                              <Link to="">Bright Sparks PLC</Link>
                            </li>
                            <li>
                              <i className="fas fa-map-marker-alt pr-1"></i>
                              Canyon Village, Ramon
                            </li>
                            <li>
                              <i className="fas fa-filter pr-1"></i>Financial
                              Services
                            </li>
                            <li>
                              <Link className="freelance" to="#">
                                <i className="fas fa-suitcase pr-1"></i>
                                Freelance
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="job-list-favourite-time">
                      <Link className="job-list-favourite order-2" to="#">
                        <i className="far fa-heart"></i>
                      </Link>
                      <span className="job-list-time order-1">
                        <i className="far fa-clock pr-1"></i>6M ago
                      </span>
                    </div>
                  </div>
                </div> */}
                  {/* <div className="col-lg-6">
                  <div className="job-list">
                    <div className="job-list-logo">
                      <img className="img-fluid" src={svg19} alt="" />
                    </div>
                    <div className="job-list-details">
                      <div className="job-list-info">
                        <div className="job-list-title">
                          <h5 className="mb-0">
                            <Link to="">Land Development Marketer</Link>
                          </h5>
                        </div>
                        <div className="job-list-option">
                          <ul className="list-unstyled">
                            <li>
                              <span>via</span>
                              <Link to="">Carphone Warehouse</Link>
                            </li>
                            <li>
                              <i className="fas fa-map-marker-alt pr-1"></i>
                              Canyon Village, Ramon
                            </li>
                            <li>
                              <i className="fas fa-filter pr-1"></i>IT &
                              Telecoms
                            </li>
                            <li>
                              <Link className="part-time" to="#">
                                <i className="fas fa-suitcase pr-1"></i>
                                Part-Time
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="job-list-favourite-time">
                      <Link className="job-list-favourite order-2" to="#">
                        <i className="far fa-heart"></i>
                      </Link>
                      <span className="job-list-time order-1">
                        <i className="far fa-clock pr-1"></i>1M ago
                      </span>
                    </div>
                  </div>
                </div> */}
                </div>

                <div className="col-12 justify-content-center d-flex mt-md-4 mt-3 ">
                  <Link className="btn btn-dark btn-lg" to="">
                    View More Jobs
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Jobs;
