import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import React, { Component } from 'react';
import Stepper from './stepper/index';
import Stepperf from './stepper/freindex';
import Header from './header';

class Jsregister extends Component {
  state = {  }
  render() { 
      return (     
          <Tabs class="register-tabs">
            <Header/>
            <div className="mini-header">
              <h2 className="jregister">Registration Page</h2>
              </div>
  <TabList >
    <Tab>Experience</Tab>
    <Tab>Fresher</Tab>
  </TabList>

  <TabPanel>
    
    <Stepper/>
    
  </TabPanel>
  <TabPanel>
    
    <Stepperf/>
  </TabPanel>
</Tabs>

       );
  }
}
 
export default Jsregister;


