import React, { Component } from "react";
import Header from "./header";
import fsr from "../assets/images/svg/fsr.png";
import exp from "../assets/images/svg/exp.png";
import {Link} from "react-router-dom";

class Button extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="container">
          <div className="col-md-6 d-flex">
            <Link id="btn" className="btn btn-primary btn-block" to="Fresher">
              Fresher
              <img className="img" src={fsr} alt="fsr" />
            </Link>
            <Link id="btn" className="btn btn-primary btn-block" to="Experience">
              Experience
              <img className="img" src={exp} alt="exp" />
            </Link>
          </div>
        </div>
        
      </React.Fragment>
    );
  }
}

export default Button;
