import React, { Component } from "react";
import postjobService from "../services/postjobService";
import { Link } from "react-router-dom";

class Sector extends Component {
  state = {
    jobs: [],
  };

  async componentDidMount() {
    const job = await postjobService.getjob();
    console.log(job);
    this.setState({ jobs: job.data });
  }

  render() {
    const list = this.state.jobs;
    // console.log(list);
    const ar = [];
    this.state.jobs.map((n) => {
      if (!ar.includes(n.category)) {
        ar.push(n.category);
      }
    });

    // console.log(ar);

    return (
      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="section-title center">
                <h2 className="title">Choose Your Sector</h2>
                <p className="mb-0">
                  Making a decision to do something – this is the first step. We
                  all know that nothing moves until someone makes a decision.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="category-style category-style-03 text-center">
                {ar.map((j) => (
                  <Link
                    to={{ pathname: "/viewjoblist", state: { list, cat: j } }}
                    className="category-item"
                  >
                    <div className="category-icon mb-4">
                      <i className="flaticon-account"></i>
                    </div>
                    <h6>{j}</h6>

                    <span className="mb-0">301 Open Position </span>
                  </Link>
                ))}
                {/* <Link to="#" className="category-item">
                <div className="category-icon mb-4">
                  <i className="flaticon-conversation"></i>
                </div>
                <h6>Apprenticeships</h6>
                <span className="mb-0">287 Open Position </span>
              </Link>
              <Link to="#" className="category-item">
                <div className="category-icon mb-4">
                  <i className="flaticon-money"></i>
                </div>
                <h6>Banking</h6>
                <span className="mb-0">542 Open Position </span>
              </Link>
              <Link to="#" className="category-item">
                <div className="category-icon mb-4">
                  <i className="flaticon-mortarboard"></i>
                </div>
                <h6>Education</h6>
                <span className="mb-0">785 Open Position </span>
              </Link>
              <Link to="#" className="category-item">
                <div className="category-icon mb-4">
                  <i className="flaticon-worker"></i>
                </div>
                <h6>Engineering</h6>
                <span className="mb-0">862 Open Position </span>
              </Link>
              <Link to="#" className="category-item">
                <div className="category-icon mb-4">
                  <i className="flaticon-businessman"></i>
                </div>
                <h6>Estate Agency</h6>
                <span className="mb-0">423 Open Position </span>
              </Link>
              <Link to="#" className="category-item">
                <div className="category-icon mb-4">
                  <i className="flaticon-coding"></i>
                </div>
                <h6>IT & Telecoms</h6>
                <span className="mb-0">253 Open Position </span>
              </Link>
              <Link to="#" className="category-item">
                <div className="category-icon mb-4">
                  <i className="flaticon-balance"></i>
                </div>
                <h6>Legal</h6>
                <span className="mb-0">689 Open Position </span>
              </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Sector;
