import React, { Component } from "react";
import Header from "./header";
import jwt from "jsonwebtoken";
import { jobpost } from "../services/postjobService";
import auth from "../services/employerloginService";
import { toast } from "react-toastify";
import employerloginService from "../services/employerloginService";

class Postjob extends Component {
  state = {
    employeremail: "",
    companyname: "",
    Aboutcompany: "",
    JobDescription: "",
    contactInfo: "",
    industry: "",
    location: "",
    requirement: "",
    salary: "",
    joiningtime: "",
    skills: "",
    vaccancies: "",
    jobtitle: "",
    category: "",
    industries: [
      "Select",
      "Human Resources",
      "Energy",
      "IT-Software, Software Services",
      "Hardware",
      "Marketing",
    ],
    Joiningtimings: ["select", "after 1 month", "after 15 days", "immediately"],
    errors: {},
  };
  handleindustries = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      this.setState({ industry: e.target.value });
    }
  };
  handleJoiningtimings = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      this.setState({ joiningtime: e.target.value });
    }
  };
  handleSubmit = async () => {
    let obj = {
      employeremail: this.state.employeremail,
      companyname: this.state.companyname,
      Aboutcompany: this.state.Aboutcompany,
      JobDescription: this.state.JobDescription,
      contactInfo: this.state.contactInfo,
      industry: this.state.industry,
      location: this.state.location,
      requirement: this.state.requirement,
      salary: this.state.salary,
      joiningtime: this.state.joiningtime,
      skills: this.state.skills,
      vaccancies: this.state.vaccancies,
      jobtitle: this.state.jobtitle,
      category: this.state.category,
    };

    const response = await jobpost(obj);
    if (!response) {
      toast.error("fill all details correctly", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success("successfully Job Posted", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setTimeout(() => {
        window.location = "/";
      }, 5000);
    }
  }
  //   toast.success("successfully Job Posted", {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });

  //   setTimeout(() => {
  //     window.location = "/";
  //   }, 1000);
  // };
  catch(ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.obj, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <section class="space-ptb bg-light">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12">
                <div class="section-title text-center">
                  <h2 class="text-primary">Post Job</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="form-group col-md-12">
                <label>Job Title *</label>
                <input
                  type="text"
                  class="form-control"
                  value={this.state.jobtitle}
                  placeholder="Enter a Title"
                  onChange={(jobtitle) =>
                    this.setState({ jobtitle: jobtitle.target.value })
                  }
                />
              </div>
              <div class="form-group col-md-12">
                <label>Job Description *</label>
                <textarea
                  class="form-control"
                  rows="4"
                  value={this.state.JobDescription}
                  onChange={(JobDescription) =>
                    this.setState({
                      JobDescription: JobDescription.target.value,
                    })
                  }
                ></textarea>
              </div>
              <div class="form-group col-md-12">
                <label>Company Name *</label>
                <input
                  type="text"
                  class="form-control"
                  value={this.state.companyname}
                  placeholder="Enter your Company Name"
                  onChange={(companyname) =>
                    this.setState({ companyname: companyname.target.value })
                  }
                />
              </div>
              <div class="form-group col-md-12">
                <label>About Company *</label>
                <textarea
                  class="form-control"
                  rows="4"
                  value={this.state.Aboutcompany}
                  onChange={(Aboutcompany) =>
                    this.setState({ Aboutcompany: Aboutcompany.target.value })
                  }
                ></textarea>
              </div>
              <div class="form-group col-md-12">
                <label>Contact Info *</label>
                <textarea
                  class="form-control"
                  rows="4"
                  value={this.state.contactInfo}
                  onChange={(contactInfo) =>
                    this.setState({ contactInfo: contactInfo.target.value })
                  }
                ></textarea>
              </div>
              <div class="form-group col-md-12 mb-md-0 select-border">
                <label>Industry *</label>
                <select
                  className="form-control"
                  onChange={(e) => this.handleindustries(e)}
                >
                  {this.state.industries &&
                    this.state.industries.map((i) => (
                      <option value={i}>{i}</option>
                    ))}
                </select>
              </div>
              <div class="form-group col-md-12">
                <label>Category *</label>
                <input
                  type="text"
                  class="form-control"
                  value={this.state.category}
                  placeholder="Enter Category"
                  onChange={(category) =>
                    this.setState({ category: category.target.value })
                  }
                />
              </div>
              <div class="form-group col-md-12">
                <label>Location *</label>
                <input
                  type="text"
                  class="form-control"
                  value={this.state.location}
                  placeholder="Enter your Location"
                  onChange={(location) =>
                    this.setState({ location: location.target.value })
                  }
                />
              </div>
              <div class="form-group col-md-12">
                <label>Requirement *</label>
                <textarea
                  class="form-control"
                  rows="4"
                  value={this.state.requirement}
                  placeholder="Enter your Requirement"
                  onChange={(requirement) =>
                    this.setState({ requirement: requirement.target.value })
                  }
                ></textarea>
              </div>
              <div class="form-group col-md-12">
                <label>Package *</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your Package"
                  value={this.state.salary}
                  onChange={(salary) =>
                    this.setState({ salary: salary.target.value })
                  }
                />
              </div>
              <div class="form-group col-md-12 mb-md-0 select-border">
                <label>Joining time *</label>
                <select
                  className="form-control"
                  onChange={(e) => this.handleJoiningtimings(e)}
                >
                  {this.state.Joiningtimings &&
                    this.state.Joiningtimings.map((r) => (
                      <option value={r}>{r}</option>
                    ))}
                </select>
              </div>
              <div class="form-group col-md-12">
                <label>Skills *</label>
                <textarea
                  class="form-control"
                  rows="4"
                  value={this.state.skills}
                  placeholder="Enter your Skills"
                  onChange={(skills) =>
                    this.setState({ skills: skills.target.value })
                  }
                ></textarea>
              </div>
              <div class="form-group col-md-12">
                <label>Vaccancies *</label>
                <input
                  type="text"
                  class="form-control"
                  value={this.state.vaccancies}
                  onChange={(vaccancies) =>
                    this.setState({ vaccancies: vaccancies.target.value })
                  }
                />
              </div>
              <div class="form-group col-md-12">
                <label>Employer email *</label>
                <input
                  type="text"
                  class="form-control"
                  value={this.state.employeremail}
                  onChange={(employeremail) =>
                    this.setState({ employeremail: employeremail.target.value })
                  }
                />
              </div>
              <button
                type="button"
                class="btn btn-primary col-sm-2"
                onClick={this.handleSubmit}
              >
                Post Job
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Postjob;
