import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./header";
import Ebanner from "./ebanner";
import Footer from "./footer";
import svg9 from "../assets/images/svg/09.svg";
import svg17 from "../assets/images/svg/17.svg";
import svg18 from "../assets/images/svg/18.svg";
import svg19 from "../assets/images/svg/19.svg";
import map from "../assets/images/map.png";

class Employerdetail extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Header />
        <Ebanner />
        <section class="space-ptb">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
                <div class="employers-list border">
                  <div class="employers-list-logo">
                    <img class="img-fluid" src={svg9} alt="" />
                  </div>
                  <div class="employers-list-details">
                    <div class="employers-list-info">
                      <div class="employers-list-title">
                        <h5 class="mb-0">Bright Sparks PLC</h5>
                      </div>
                      <div class="employers-list-option">
                        <ul class="list-unstyled">
                          <li>
                            <i class="fas fa-map-marker-alt pr-1"></i>214 West
                            Arnold St.New York
                          </li>
                          <li>
                            <i class="fa fa-phone fa-flip-horizontal pl-1"></i>
                            (123) 345-6789
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="employers-list-position">
                    <Link class="btn btn-sm btn-dark" to="#">
                      17 Open position
                    </Link>
                  </div>
                </div>
                <div class="border p-4 mt-4 mt-md-5">
                  <div class="row">
                    <div class="col-md-4 col-sm-6 mb-4">
                      <div class="d-flex">
                        <i class="font-xll text-primary align-self-center flaticon-binoculars"></i>
                        <div class="feature-info-content pl-3">
                          <label class="mb-1">Viewed</label>
                          <span class="mb-0 font-weight-bold d-block text-dark">
                            164
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                      <div class="d-flex">
                        <i class="font-xll text-primary align-self-center flaticon-placeholder"></i>
                        <div class="feature-info-content pl-3">
                          <label class="mb-1">Locations</label>
                          <span class="mb-0 font-weight-bold d-block text-dark">
                            United States
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                      <div class="d-flex">
                        <i class="font-xll text-primary align-self-center flaticon-list"></i>
                        <div class="feature-info-content pl-3">
                          <label class="mb-1">Categories</label>
                          <span class="mb-0 font-weight-bold d-block text-dark">
                            Arts, Design, Media
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-md-0 mb-4">
                      <div class="d-flex">
                        <i class="font-xll text-primary align-self-center flaticon-time"></i>
                        <div class="feature-info-content pl-3">
                          <label class="mb-1">Since</label>
                          <span class="mb-0 font-weight-bold d-block text-dark">
                            Management
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-sm-0 mb-4">
                      <div class="d-flex">
                        <i class="font-xll text-primary align-self-center flaticon-users"></i>
                        <div class="feature-info-content pl-3">
                          <label class="mb-1">Team Size</label>
                          <span class="mb-0 font-weight-bold d-block text-dark">
                            15
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                      <div class="d-flex">
                        <i class="font-xll text-primary align-self-center flaticon-add-user"></i>
                        <div class="feature-info-content pl-3">
                          <label class="mb-1">Followers</label>
                          <span class="mb-0 font-weight-bold d-block text-dark">
                            50
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4 mt-md-5 mb-4 mb-md-5">
                  <h5 class="mb-3 mb-md-4">About Us</h5>
                  <p>
                    SMART is an acronym for Specific, Measurable, Achievable,
                    Realistic and Time Sensitive – S-M-A-R-T. Knowing what you
                    want and setting SMART goals as mileposts on your quest
                    cannot help but give you clarity!
                  </p>
                  <p class="font-italic mb-0">
                    Focus is having the unwavering attention to complete what
                    you set out to do. There are a million distractions in every
                    facet of our lives. Telephones and e-mail, clients and
                    managers, spouses and kids, TV, newspapers and radio – the
                    distractions are everywhere and endless. Everyone wants a
                    piece of us and the result can be totally overwhelming.
                  </p>
                </div>
                <hr />

                <div class="mt-4 mt-md-5">
                  <div id="portfolio" class=" popup-gallery">
                    <h5 class="mb-3">Image Gallery</h5>
                    <div
                      class="owl-carousel mb-lg-5 mb-4"
                      data-nav-arrow="false"
                      data-items="3"
                      data-md-items="3"
                      data-sm-items="3"
                      data-xs-items="2"
                      data-space="15"
                    >
                      <div class="item">
                        <Link class="portfolio-img" to="">
                          <img class="img-fluid" src="" alt="" />
                        </Link>
                      </div>
                      <div class="item">
                        <Link class="portfolio-img" to="">
                          <img class="img-fluid" src="" alt="" />
                        </Link>
                      </div>
                      <div class="item">
                        <Link class="portfolio-img" to="">
                          <img class="img-fluid" src="" alt="" />
                        </Link>
                      </div>
                    </div>
                    <p class="mb-0">
                      The price is something not necessarily defined as
                      financial. It could be time, effort, sacrifice, money or
                      perhaps, something else. The point is that we must be
                      fully aware of the price and be willing to pay it, if we
                      want to have success.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mt-4 mt-lg-0">
                <div class="sidebar mb-0">
                  <div class="widget">
                    <div class="company-detail-meta justify-content-start">
                      <ul class="list-unstyled">
                        <li>
                          <div class="share-box">
                            <Link to="#">
                              {" "}
                              <i class="fas fa-share-alt"></i>
                              <span class="pl-2">Share</span>
                            </Link>
                            <ul class="list-unstyled share-box-social">
                              <li>
                                {" "}
                                <Link to="#">
                                  <i class="fab fa-facebook-f"></i>
                                </Link>{" "}
                              </li>
                              <li>
                                {" "}
                                <Link to="#">
                                  <i class="fab fa-twitter"></i>
                                </Link>{" "}
                              </li>
                              <li>
                                {" "}
                                <Link to="#">
                                  <i class="fab fa-linkedin"></i>
                                </Link>{" "}
                              </li>
                              <li>
                                {" "}
                                <Link to="#">
                                  <i class="fab fa-instagram"></i>
                                </Link>{" "}
                              </li>
                              <li>
                                {" "}
                                <Link to="#">
                                  <i class="fab fa-pinterest"></i>
                                </Link>{" "}
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <Link to="#">
                            <i class="fas fa-print"></i>
                            <span class="pl-2">Print</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="widget">
                    <div class="widget-title">
                      <h5>Contact Bright Sparks PLC</h5>
                    </div>
                    <div class="company-contact-inner widget-box">
                      <form>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Full name"
                          />
                        </div>
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            placeholder="Email address"
                          />
                        </div>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Subject"
                          />
                        </div>
                        <div class="form-group">
                          <textarea
                            class="form-control"
                            rows="3"
                            placeholder="Message"
                          ></textarea>
                        </div>
                        <Link
                          class="btn btn-primary btn-outline-primary btn-block"
                          to="#"
                        >
                          Send Email
                        </Link>
                      </form>
                    </div>
                  </div>
                  <div class="widget">
                    <div class="company-address widget-box">
                      <div class="company-address-map">
                        <iframe src={map}></iframe>
                      </div>
                      <ul class="list-unstyled mt-3">
                        <li>
                          <Link to="#">
                            <i class="fas fa-link fa-fw"></i>
                            <span class="pl-2">www.infojob.com</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="tel:+905389635487">
                            <i class="fas fa-phone fa-flip-horizontal fa-fw"></i>
                            <span class="pl-2">+(456) 478-2589</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="mailto:ali.potenza@job.com">
                            <i class="fas fa-envelope fa-fw"></i>
                            <span class="pl-2">support@jobber.demo</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="widget">
                    <div class="widget-title">
                      <h5>Similar Jobs</h5>
                    </div>
                    <div class="similar-jobs-item widget-box">
                      <div class="job-list">
                        <div class="job-list-logo">
                          <img class="img-fluid" src={svg17} alt="" />
                        </div>
                        <div class="job-list-details">
                          <div class="job-list-info">
                            <div class="job-list-title">
                              <h6>
                                <Link to="job-detail.html">
                                  Designer Required
                                </Link>
                              </h6>
                            </div>
                            <div class="job-list-option">
                              <ul class="list-unstyled">
                                <li>
                                  <span>via</span>
                                  <Link to="employer-detail.html">
                                    Trout Design Ltd
                                  </Link>
                                </li>
                                <li>
                                  <Link class="freelance" to="#">
                                    <i class="fas fa-suitcase pr-1"></i>
                                    Freelance
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="job-list">
                        <div class="job-list-logo">
                          <img class="img-fluid" src={svg18} alt="" />
                        </div>
                        <div class="job-list-details">
                          <div class="job-list-info">
                            <div class="job-list-title">
                              <h6>
                                <Link to="job-detail.html">
                                  Post Room Operative
                                </Link>
                              </h6>
                            </div>
                            <div class="job-list-option">
                              <ul class="list-unstyled">
                                <li>
                                  <span>via</span>
                                  <Link to="employer-detail.html">
                                    LawnHopper
                                  </Link>
                                </li>
                                <li>
                                  <Link class="part-time" to="#">
                                    <i class="fas fa-suitcase pr-1"></i>
                                    Part-Time
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="job-list">
                        <div class="job-list-logo">
                          <img class="img-fluid" src={svg19} alt="" />
                        </div>
                        <div class="job-list-details">
                          <div class="job-list-info">
                            <div class="job-list-title">
                              <h6>
                                <Link to="job-detail.html">
                                  Stockroom Assistant
                                </Link>
                              </h6>
                            </div>
                            <div class="job-list-option">
                              <ul class="list-unstyled">
                                <li>
                                  <span>via</span>
                                  <Link to="employer-detail.html">
                                    Rippin LLC
                                  </Link>
                                </li>
                                <li>
                                  <Link class="temporary" to="#">
                                    <i class="fas fa-suitcase pr-1"></i>
                                    Temporary
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="job-list">
                        <div class="job-list-logo">
                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 726 726"><path fill="#4D4D4D" d="M332.45 400.625l22.501-81.37c.683-2.276 2.039-3.187 4.321-3.187h18.179c2.505 0 3.644.911 4.328 3.187l23.184 81.827 20.223-66.375h-10.908c-2.277 0-3.415-1.128-3.415-3.405v-11.83c0-2.494 1.138-3.405 3.415-3.405h29.084c3.871 0 5.464 2.959 4.553 6.137l-31.37 103.187c-.674 2.277-2.039 3.188-4.315 3.188h-20.453c-2.275 0-3.642-.911-4.325-3.405l-21.591-80.242-22.5 80.459c-.684 2.277-2.05 3.188-4.316 3.188h-20.461c-2.272 0-3.638-.911-4.316-3.188l-24.778-90.684h-11.814c-2.277 0-3.415-1.128-3.415-3.405v-11.83c0-2.494 1.138-3.405 3.415-3.405h29.772c2.271 0 3.638.911 4.326 3.405l20.676 81.153z"/><path fill="#24BAC9" d="M477.723 542.344c-28.339-15.399-46.472-42.319-51.937-71.734-36.432 21.135-82.665 23.536-122.342 1.946-29.927-16.218-67.384-5.133-83.644 24.798-16.26 29.922-5.169 67.356 24.757 83.648 91.365 49.616 200.396 35.634 276.19-26.724-14.976-.891-29.734-4.74-43.024-11.934z"/><path fill="#F03955" d="M554.54 205.553c-.89 14.78-4.927 29.342-12.213 42.735-14.903 27.499-41.036 46.314-71.745 51.945 21.185 36.43 23.575 82.673 2.018 122.313-16.26 29.921-5.165 67.396 24.747 83.678 29.933 16.239 67.398 5.143 83.649-24.777 49.597-91.254 35.665-200.092-26.456-275.894z"/><path fill="#59B89C" d="M183.66 477.732c14.965-27.51 41.058-46.295 71.761-51.967-21.161-36.421-23.567-82.622-2.013-122.345 16.239-29.911 5.169-67.356-24.758-83.595-29.942-16.28-67.383-5.185-83.643 24.736-49.618 91.254-35.655 200.175 26.517 275.957.879-14.584 4.75-29.186 12.136-42.786z"/><path fill="#F09502" d="M481.447 145.018C390.079 95.36 281.052 109.384 205.26 171.72c14.96.9 29.755 4.751 43.024 11.954 28.333 15.4 46.477 42.269 51.957 71.744 36.437-21.195 82.665-23.557 122.316-2.007 29.933 16.249 67.39 5.174 83.649-24.757 16.26-29.963 5.195-67.376-24.759-83.636z"/></svg> */}
                        </div>
                        <div class="job-list-details mb-0">
                          <div class="job-list-info">
                            <div class="job-list-title">
                              <h6>
                                <Link to="job-detail.html">
                                  Research Administrator
                                </Link>
                              </h6>
                            </div>
                            <div class="job-list-option">
                              <ul class="list-unstyled">
                                <li>
                                  <span>via</span>
                                  <Link to="Employerdetail">
                                    Trophy and Sons
                                  </Link>
                                </li>
                                <li>
                                  <Link class="full-time" to="#">
                                    <i class="fas fa-suitcase pr-1"></i>Full
                                    time
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Employerdetail;
