import React, { Component } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import PBanner from "./profilebanner";
import Dashboard from "./dashboard";
import {Link} from 'react-router-dom';
class Changepassword extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Header />
        <PBanner />
        <Dashboard />

        <section>
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="user-dashboard-info-box">
                  <div class="section-title-02 mb-4">
                    <h4>Change Password</h4>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <form class="form-row">
                        <div class="form-group col-md-12">
                          <label>Current Password</label>
                          <input
                            type="password"
                            class="form-control"
                            value=""
                          />
                        </div>
                        <div class="form-group col-md-12">
                          <label>New Password</label>
                          <input
                            type="password"
                            class="form-control"
                            value=""
                          />
                        </div>
                        <div class="form-group col-md-12 mb-0">
                          <label>Confirm Password</label>
                          <input
                            type="password"
                            class="form-control"
                            value=""
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <Link class="btn btn-lg btn-primary" to="#">
                  Change Password
                </Link>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Changepassword;
