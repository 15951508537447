import jwtDecode from 'jwt-decode';
import http from './httpService';
import {apiUrl} from '../config.json';


const apiEndpoint = apiUrl + '/users/auth';
const tokenKey="token";

http.setJwt(getJwt());

export async function ulogin(obj){
   const {data:jwt}=await http.post(apiEndpoint,{obj});
   localStorage.setItem(tokenKey,jwt); 
}

export function loginWithJwt(jwt){
   localStorage.setItem(tokenKey,jwt);  
}

export function logout(){
   localStorage.removeItem(tokenKey);

}

export function getCurrentUser(){
   try{
      const jwt = localStorage.getItem(tokenKey);
      return jwtDecode(jwt);
     
    }
    catch(ex){
       return null;
    } 
   
}

export function getJwt(){
   return localStorage.getItem(tokenKey);
};

export default {
   ulogin,
   loginWithJwt,
   logout,
   getCurrentUser,
   getJwt
};