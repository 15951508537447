import React, { Component } from "react";
import {Link } from "react-router-dom";

class Post extends Component {
  state = {};
  render() {
    return (
      <section id="banner2" className="space-ptb bg-holder bg-overlay-black-60">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="section-title">
                <span className="text-primary lead font-weight-bold d-block mb-3">
                  Post OR Get a job
                </span>
                <h2 className="text-white">
                  Looking for Post OR Get a job? We have end-to-end solutions
                  that can keep up with your criteria.
                </h2>
              </div>
              <Link className="btn btn-primary mb-2 mb-sm-0" to="#">
                Post a job
              </Link>
              <Link className="btn btn-white mb-2 mb-sm-0" to="#">
                Browse jobs
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Post;
