import React, { Component } from "react";
import Header from "./header";
import Joi from "joi";
import jwt from "jsonwebtoken";
import { toast } from "react-toastify";
import { fregister } from "../services/fresherService";
import { Link } from "react-router-dom";
import auth from "../services/authServices";
import fresherService from "../services/fresherService";

class Fresher extends Component {
  state = {
    name: "",
    email: "",
    password: "",
    contactNumber: "",
    currentcity: "",
    resumeURL: "",
    education: {
      HighestQualifications: [
        "select",
        "Doctorate/phd",
        "Masters/Post-Graduation",
        "Graduation/Diploma",
        "Inter",
        "SSC",
      ],
      Course: [
        "select",
        "B.Tech/B.E",
        "B.sc",
        "B.Com",
        "B.A",
        "B.Arch",
        "B.Pharm",
        "B.B.A",
        "M.B.B.S",
        "B.Ed",
        "Diploma",
        "M.Tech",
        "M.Pharm",
      ],
      Specialization: [
        "select",
        "CSE",
        "ECE",
        "EEE",
        "MECHANICAL",
        "IT",
        "CIVIL",
        "Agriculture",
        "Automobile",
        "Chemical",
        "Bio-medical",
        "others",
      ],
      College: "",
      coursetype: "",
      passingyear: [
        "2002",
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
      ],
      Skills: "",
    },
    Describe: {
      describe: "",
      PreferredWorkLocation: "",
      JobType: "",
      EmployementType: "",
      ITSKILLS: "",
      Gender: ["Male", "Female", "Transgender"],
    },
    var: "",
    temp: "",
    exp: "",
  };
  schema = {
    name: Joi.string().required().label("Name"),
    email: Joi.string().required().label("Email"),
    password: Joi.string().required().label("Password"),
    contactNumber: Joi.number().required().label("Mobile Number"),
    currentcity: Joi.string().required().label("Currentcity"),
    resumeURL: Joi.string().label("resumeURL"),
    education: Joi.string().required().label("education"),
    describe: Joi.string().required().label("describe"),
  };

  handlehighestqualifications = (e) => {
    if (e.target.value) {
      this.setState({ HighestQualifications: e.target.value });
    }
  };
  handleCourses = (e) => {
    if (e.target.value) {
      this.setState({ Course: e.target.value });
    }
  };
  handleSpecializations = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      this.setState({ Specialization: e.target.value });
    }
  };
  handleyears = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      this.setState({ passingyear: e.target.value });
    }
  };
  handlegender = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      this.setState({ Gender: e.target.value });
    }
  };

  handleSubmit = async () => {
    try {
      const data = {
        name: this.state.name,
        contactNumber: this.state.contactNumber,
        password: this.state.password,
        email: this.state.email,
        currentcity: this.state.currentcity,
        education: this.state.education,
        describe: this.state.describe,
        resumeURL: this.state.resumeURL,
      };
      console.log(data)
      const response = await fregister(data);
      //auth.emploginWithJwt(response.headers["x-auth-token"]);
      console.log(response);
      // localStorage.setItem("token", jwt);
      if(!response){
        toast.error("fill all details correct", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      else {
        toast.success("successfully register", {
          position: toast.POSITION.TOP_RIGHT,
        });

        setTimeout(() => {
          window.location = "/";
        }, 5000);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  radiohandleChange = async (value) => {
    await this.setState({ var: value });
    console.log(this.state.var);
  };

  radiohandleChange1 = async (value) => {
    await this.setState({ temp: value });
    console.log(this.state.temp);
  };

  radiohandleChange3 = async (value) => {
    await this.setState({ exp: value });
    console.log(this.state.exp);
  };

  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="space-ptb bg-light">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="section-title text-center">
                  <h4 className="text-primary">Register for Freshers</h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <div className="row">
            <div className="form-group col-sm-3">
              <label for="exampleInputPassword1">Name *</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your name"
                value={this.state.name}
                onChange={(name) => this.setState({ name: name.target.value })}
              />
            </div>

            <div className="form-group col-sm-3">
              <label for="exampleInputEmail1">Email address *</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Pleas enter your email"
                value={this.state.email}
                onChange={(email) =>
                  this.setState({ email: email.target.value })
                }
              />
            </div>

            <div className="form-group col-sm-3">
              <label for="exampleInputPassword1">Password *</label>
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Minimum 8 characters"
                value={this.state.password}
                onChange={(password) =>
                  this.setState({ password: password.target.value })
                }
              />
            </div>

            <div className="form-group col-sm-3">
              <label for="exampleInputPassword1">Mobile Number *</label>
              <input
                type="Mobile Number"
                className="form-control"
                placeholder="Please enter your mobile number"
                value={this.state.contactNumber}
                onChange={(contactNumber) =>
                  this.setState({ contactNumber: contactNumber.target.value })
                }
              />
            </div>

            <div className="form-group col-sm-3">
              <label for="exampleInputPassword1">Current City *</label>
              <input
                type="Current City"
                className="form-control"
                placeholder="Please enter your Current City  "
                value={this.state.currentcity}
                onChange={(currentcity) =>
                  this.setState({ currentcity: currentcity.target.value })
                }
              />
            </div>

            <div className="form-group col-sm-3">
              <label>Upload Resume *</label>
              <input
                type="file"
                className="form-control"
                value=""
                placeholder="Please choose your Resume"
                value={this.state.resumeURL}
                onChange={(resumeURL) =>
                  this.setState({ resumeURL: resumeURL.target.value })
                }
              />
            </div>

            <div className="form-group col-sm-3 select-border">
              <label>Highest Qualification *</label>
              <select
                className="form-control"
                onChange={(e) => this.handlehighestqualifications(e)}
              >
                {this.state.education.HighestQualifications.map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>
              {/* <select className="form-control basic-select">
                <option value="1">Select Your Highest Qualification</option>
  <option value="2">Doctorate/phd</option>
  <option value="3">Masters/Post-Graduation</option>
  <option value="4">Graduation/Diploma</option>
  <option value="5">12th</option>
  <option value="6">10th</option>
  <option value="7">Below 10th</option>
                </select> */}
            </div>

            <div className="form-group col-sm-3 select-border">
              <label>Course *</label>
              {/* <select className="form-control basic-select">
                <option value="1">Select Your Course</option>
  <option value="2">B.Tech/B.E.</option>
  <option value="3">B.com</option>
  <option value="4">B.sc</option>
  <option value="5">12th</option>
  <option value="4">B.A</option>
  <option value="7">Diploma</option>
  <option value="7">B.B.A/B.M.S</option>
  <option value="7">BCA</option>
  <option value="7">B.Arch</option>
  <option value="7">B.Des</option>
  <option value="7">B.Ed</option>
  <option value="7">B.Pharma</option>
  <option value="7">MBBS</option>
  <option value="7">Other</option>
                 </select> */}
              <select
                className="form-control"
                onChange={(e) => this.handleCourses(e)}
              >
                {this.state.education.Course.map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>
            </div>
            <div className="form-group col-sm-3 select-border">
              <label>Specialization *</label>
              <select
                className="form-control"
                onChange={(e) => this.handleSpecializations(e)}
              >
                {this.state.education.Specialization.map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>

              {/* <select className="form-control basic-select">
                <option value="1">Select Your Specialization  </option>
  <option value="2">Agriculture</option>
  <option value="3">Automobile</option>
  <option value="4">Aviation</option>
  <option value="5">Bio-chemistry/Bio-Technology</option>
  <option value="4">Bio-Medical</option>
  <option value="7">Chemical</option>
  <option value="7">Other</option>
</select> */}
            </div>

            <div className="form-group col-sm-3">
              <label for="exampleInputPassword1">University /College *</label>
              <input
                type="current city"
                className="form-control"
                placeholder="Institute Name"
                value={this.state.College}
                onChange={(College) =>
                  this.setState({ College: College.target.value })
                }
              />
            </div>

            <div className="form-group col-sm-3 select-border">
              <label>Passing Year *</label>
              {/* <select className="form-control basic-select">
                <option value="1">Please select the passing year</option>
  <option value="2">2009</option>
  <option value="3">2010</option>
  <option value="3">2011</option>
  <option value="5">2012</option>
  <option value="3">2013</option>
  <option value="7">2013</option>
  <option value="8">2015</option>
  <option value="9">2013</option>
  <option value="10">2017</option>
  <option value="11">2018</option>
  <option value="12">2019</option>
  <option value="13">2020</option>
  <option value="13">2021</option>
  <option value="15">2022</option>
  <option value="13">2023</option>
  <option value="17">2023</option>
  <option value="18">2025</option>
</select> */}
              <select
                className="form-control"
                onChange={(e) => this.handleyears(e)}
              >
                {this.state.education.passingyear.map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>
            </div>

            <div className="form-group col-sm-3">
              <label for="exampleInputPassword1">Skills *</label>
              <input
                type="Skills"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Enter your areas of expertise/Specialization"
                value={this.state.Skills}
                onChange={(Skills) =>
                  this.setState({ Skills: Skills.target.value })
                }
              />
            </div>
            <div
              className="col-sm-3"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <label>Course Type *</label>
              <div className="row">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value={this.state.var}
                    onChange={() => this.radiohandleChange("Fulltime")}
                  />
                  <label class="form-check-label " for="inlineRadio1">
                    Full Time
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value={this.state.var}
                    onChange={() => this.radiohandleChange("Parttime")}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Part Time
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    value={this.state.var}
                    onChange={() => this.radiohandleChange("correspondence")}
                  />
                  <label class="form-check-label" for="inlineRadio3">
                    Correspondence
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group col-sm-3">
              <label for="exampleInputPassword1">
                Preferred Work Location *
              </label>
              <input
                type="Preferred Work Location"
                className="form-control"
                placeholder="Select Location"
                value={this.state.PreferredWorkLocation}
                onChange={(PreferredWorkLocation) =>
                  this.setState({
                    PreferredWorkLocation: PreferredWorkLocation.target.value,
                  })
                }
              />
            </div>

            <div
              className="col-sm-3"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <label>Job Type *</label>
              <div className="row">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOption"
                    id="inlineRadio"
                    value={this.state.temp}
                    onChange={() => this.radiohandleChange1("Perminent")}
                  />
                  <label class="form-check-label " for="inlineRadio1">
                    Perminent
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOption"
                    id="inlineRadio"
                    value={this.state.temp}
                    onChange={() => this.radiohandleChange1("Temporary")}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Temporary
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOption"
                    id="inlineRadio"
                    value={this.state.temp}
                    onChange={() => this.radiohandleChange1("Both")}
                  />
                  <label class="form-check-label" for="inlineRadio3">
                    Both
                  </label>
                </div>
              </div>
            </div>
            <div
              className="col-sm-3"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <label>Employement Type *</label>
              <div className="row">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOption3"
                    id="inlineRadio3"
                    value={this.state.exp}
                    onChange={() => this.radiohandleChange3("Fulltime")}
                  />
                  <label class="form-check-label " for="inlineRadio1">
                    Fulltime
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOption3"
                    id="inlineRadio3"
                    value={this.state.exp}
                    onChange={() => this.radiohandleChange3("Parttime")}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Parttime
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOption3"
                    id="inlineRadio3"
                    value={this.state.exp}
                    onChange={() => this.radiohandleChange3("Both")}
                  />
                  <label class="form-check-label" for="inlineRadio3">
                    Both
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group col-sm-3">
              <label for="exampleInputPassword1">IT Skills *</label>
              <input
                type="IT Skills"
                className="form-control"
                placeholder="Please enter your Skills"
                value={this.state.ITSKILLS}
                onChange={(ITSKILLS) =>
                  this.setState({ ITSKILLS: ITSKILLS.target.value })
                }
              />
            </div>
            <div className="form-group col-sm-3 select-border">
              <label>Gender *</label>
              {/* <select className="form-control basic-select">
                  <option value="value 03">Male</option>
                  <option value="value 01">Female</option>
                  <option value="value 01">Transgender</option>
                </select> */}
              <select
                className="form-control"
                onChange={(e) => this.handlegender(e)}
              >
                {this.state.Describe.Gender.map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>
            </div>
            <div className="form-group col-sm-4">
              <label for="exampleInputPassword1">
                Describe your professional background in one line
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Jobseeker  with B.Tech/B.E. in Computers currently living in Delhi"
                value={this.state.describe}
                onChange={(describe) =>
                  this.setState({ describe: describe.target.value })
                }
              />
            </div>
          </div>
          <div class="row">
            <button
              type="button"
              className="btn btn-primary col-sm-2"
              onClick={this.handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Fresher;
