import React from "react";

const Select = ({ name, label, options, error, ...rest }) => {
  return (
    
      <div class="form-group col-md-6 mb-md-0 select-border">
      <label htmlFor={name}>{label}</label>
      <select name={name} id={name} {...rest} className="form-control">
        {/* <option value="" />
        {options.map((option) => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option> */}
        ))
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
    
  );
};

export default Select;