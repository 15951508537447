import http from "./httpService";
import { apiUrl} from '../config.json';

const apiEndpoint = apiUrl +"/users/register";

export function fregister(user){
    //console.log(user)
    return http.post(apiEndpoint,{
        name:user.name,
        email:user.email,
        password:user.password,
        contactNumber:user.contactNumber,
        gender:user.gender,
        preferedworklocation:user.preferedworklocation,
        skills:user.skills,
        resumeURL:user.resumeURL,
        education:user.education
          
    })
}
export default fregister;