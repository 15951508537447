import jwtDecode from 'jwt-decode';
import http from './httpService';
import {apiUrl} from '../config.json';


const apiEndpoint = apiUrl +'/employers/auth';
const tokenKey="token";

http.setJwt(getJwt());

export async function emplogin(obj){
   
   const {data:jwt}=await http.post(apiEndpoint,{employeremail:obj.employeremail,password:obj.password});
   localStorage.setItem(tokenKey,jwt); 
}

export function emploginWithJwt(jwt){
   localStorage.setItem(tokenKey,jwt);  
}

export function logout(){
   localStorage.removeItem(tokenKey);

}

export function empgetCurrentUser(){
   try{
      const jwt = localStorage.getItem(tokenKey);
      return jwtDecode(jwt);
     
    }
    catch(ex){
       return null;
    } 
   
}

export function getJwt(){
   return localStorage.getItem(tokenKey);
};


export default {
   emplogin,
   emploginWithJwt,
   logout,
   empgetCurrentUser,
   getJwt
   
};