import React, { Component } from "react";
import banner from "../assets/images/bg/banner-01.jpg";
import {Link} from "react-router-dom";

class Esidebar extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="col-lg-3">
          <div className="sidebar">
            <div className="widget">
              <div className="search">
                <i className="fas fa-search"></i>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search Keywords"
                />
              </div>
            </div>
            <div className="widget">
              <div className="locations">
                <i className="far fa-compass"></i>
                <input
                  className="form-control"
                  type="text"
                  placeholder="All Locations"
                />
              </div>
            </div>
            <div className="widget">
              <div className="widget-title widget-collapse">
                <h6>Date Posted</h6>
                <Link
                  className="ml-auto"
                  data-toggle="collapse"
                  to="#dateposted"
                  role="button"
                  aria-expanded="false"
                  aria-controls="dateposted"
                >
                  {" "}
                  <i className="fas fa-chevron-down"></i>{" "}
                </Link>
              </div>
              <div className="collapse show" id="dateposted">
                <div className="widget-content">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="dateposted1"
                    />
                    <label className="custom-control-label" for="dateposted1">
                      Last hour
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="dateposted2"
                    />
                    <label className="custom-control-label" for="dateposted2">
                      Last 24 hour
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="dateposted3"
                    />
                    <label className="custom-control-label" for="dateposted3">
                      Last 7 days
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="dateposted4"
                    />
                    <label className="custom-control-label" for="dateposted4">
                      Last 14 days
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="dateposted5"
                    />
                    <label className="custom-control-label" for="dateposted5">
                      Last 30 days
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="widget">
              <div className="widget-title widget-collapse">
                <h6>Specialism</h6>
                <Link
                  className="ml-auto"
                  data-toggle="collapse"
                  to="#specialism"
                  role="button"
                  aria-expanded="false"
                  aria-controls="specialism"
                >
                  {" "}
                  <i className="fas fa-chevron-down"></i>{" "}
                </Link>
              </div>
              <div className="collapse show" id="specialism">
                <div className="widget-content">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="specialism1"
                    />
                    <label className="custom-control-label" for="specialism1">
                      IT Contractor
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="specialism2"
                    />
                    <label className="custom-control-label" for="specialism2">
                      Charity & Voluntary
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="specialism3"
                    />
                    <label className="custom-control-label" for="specialism3">
                      Digital & Creative
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="specialism4"
                    />
                    <label className="custom-control-label" for="specialism4">
                      Estate Agency
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="specialism5"
                    />
                    <label className="custom-control-label" for="specialism5">
                      Graduate
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="widget">
              <div className="widget-title widget-collapse">
                <h6>Job Type</h6>
                <Link
                  className="ml-auto"
                  data-toggle="collapse"
                  to="#jobtype"
                  role="button"
                  aria-expanded="false"
                  aria-controls="jobtype"
                >
                  {" "}
                  <i className="fas fa-chevron-down"></i>{" "}
                </Link>
              </div>
              <div className="collapse show" id="jobtype">
                <div className="widget-content">
                  <div className="custom-control custom-checkbox fulltime-job">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="jobtype1"
                    />
                    <label className="custom-control-label" for="jobtype1">
                      Full Time
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox parttime-job">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="jobtype2"
                    />
                    <label className="custom-control-label" for="jobtype2">
                      Part-Time
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox freelance-job">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="jobtype3"
                    />
                    <label className="custom-control-label" for="jobtype3">
                      Freelance
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox temporary-job">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="jobtype4"
                    />
                    <label className="custom-control-label" for="jobtype4">
                      Temporary
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="widget">
              <div className="widget-title widget-collapse">
                <h6>Experience</h6>
                <Link
                  className="ml-auto"
                  data-toggle="collapse"
                  to="#experience"
                  role="button"
                  aria-expanded="false"
                  aria-controls="experience"
                >
                  {" "}
                  <i className="fas fa-chevron-down"></i>{" "}
                </Link>
              </div>
              <div className="collapse show" id="experience">
                <div className="widget-content">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="experience1"
                    />
                    <label className="custom-control-label" for="experience1">
                      Fresher
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="experience2"
                    />
                    <label className="custom-control-label" for="experience2">
                      Less than 1 year
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="experience3"
                    />
                    <label className="custom-control-label" for="experience3">
                      2 Year
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="experience4"
                    />
                    <label className="custom-control-label" for="experience4">
                      3 Year
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="experience5"
                    />
                    <label className="custom-control-label" for="experience5">
                      4 Year
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="widget">
              <div className="widget-title widget-collapse">
                <h6>Offered Salary</h6>
                <Link
                  className="ml-auto"
                  data-toggle="collapse"
                  to="#Offeredsalary"
                  role="button"
                  aria-expanded="false"
                  aria-controls="Offeredsalary"
                >
                  {" "}
                  <i className="fas fa-chevron-down"></i>{" "}
                </Link>
              </div>
              <div className="collapse show" id="Offeredsalary">
                <div className="widget-content">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="Offeredsalary1"
                    />
                    <label
                      className="custom-control-label"
                      for="Offeredsalary1"
                    >
                      10k - 20k
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="Offeredsalary2"
                    />
                    <label
                      className="custom-control-label"
                      for="Offeredsalary2"
                    >
                      20k - 30k
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="Offeredsalary3"
                    />
                    <label
                      className="custom-control-label"
                      for="Offeredsalary3"
                    >
                      30k - 40k
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="Offeredsalary4"
                    />
                    <label
                      className="custom-control-label"
                      for="Offeredsalary4"
                    >
                      40k - 50k
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="Offeredsalary5"
                    />
                    <label
                      className="custom-control-label"
                      for="Offeredsalary5"
                    >
                      50k - 60k
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="widget">
              <div className="widget-title widget-collapse">
                <h6>Gender</h6>
                <Link
                  className="ml-auto"
                  data-toggle="collapse"
                  to="#gender"
                  role="button"
                  aria-expanded="false"
                  aria-controls="gender"
                >
                  <i className="fas fa-chevron-down"></i>
                </Link>
              </div>
              <div className="collapse show" id="gender">
                <div className="widget-content">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="gender1"
                    />
                    <label className="custom-control-label" for="gender1">
                      Male
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="gender2"
                    />
                    <label className="custom-control-label" for="gender2">
                      Female
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="widget">
              <div className="widget-title widget-collapse">
                <h6>Qualification</h6>
                <Link
                  className="ml-auto"
                  data-toggle="collapse"
                  to="#qualification"
                  role="button"
                  aria-expanded="false"
                  aria-controls="qualification"
                >
                  {" "}
                  <i className="fas fa-chevron-down"></i>
                </Link>
              </div>
              <div className="collapse show" id="qualification">
                <div className="widget-content">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="qualification1"
                    />
                    <label
                      className="custom-control-label"
                      for="qualification1"
                    >
                      Matriculation
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="qualification2"
                    />
                    <label
                      className="custom-control-label"
                      for="qualification2"
                    >
                      Intermediate
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="qualification3"
                    />
                    <label
                      className="custom-control-label"
                      for="qualification3"
                    >
                      Graduate
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="widget">
              <div className="widget-add">
                <img className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Esidebar;
