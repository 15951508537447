import React, { Component } from "react";
import SideBar from "./sidebar";
import Banner from "./banner";
import Header from "./header";
import Footer from "./footer";
import svg from "../assets/images/svg/10.svg";
import Sector from "../components/sector";
import {Link} from 'react-router-dom';

class TJobList extends Component {
  state = {};
  render() {
    const dd = this.props.location.state.i;
    return (
      <React.Fragment>
        <Header />
        <Banner history={this.props.history}/>

        <section className="space-ptb">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-md-12">
                    
                    {/* <div className="job-list border"> */}
                      
                      {/* <div className=" job-list-logo">
                        <img className="img-fluid" src={svg} alt="" />
                      </div> */}

                      {/* <div className="job-list-details">
                        <div className="job-list-info">
                          <div className="job-list-title">
                            <h5 className="mb-0"></h5>
                          </div>
                          <div className="job-list-option">
                            <ul className="list-unstyled">
                              <li>
                                <i className="fas fa-map-marker-alt pr-1"></i>{" "}
                              </li>
                              <li>
                                <i className="fas fa-phone fa-flip-horizontal fa-fw"></i>
                                <span className="pl-2"></span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="job-list-favourite-time">
                        <Link className="job-list-favourite order-2" to="#">
                          <i className="far fa-heart"></i>
                        </Link>
                        <span className="job-list-time order-1">
                          <i className="far fa-clock pr-1"></i>2W ago
                        </span>
                      </div> */}
                    {/* </div> */}
                  </div>
                </div>
                <div className="border p-4 mt-4 mt-lg-5">
                  <div className="row">
                    <div className="col-md-4 col-sm-6 mb-4">
                      <div className="d-flex">
                        <i className="font-xll text-primary align-self-center flaticon-debit-card"></i>
                        <div className="feature-info-content pl-3">
                          <label className="mb-1">Offered Salary</label>
                          <span className="mb-0 font-weight-bold d-block text-dark">
                            £15,000 - £20,000
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-4">
                      <div className="d-flex">
                        <i className="font-xll text-primary align-self-center flaticon-love"></i>
                        <div className="feature-info-content pl-3">
                          <label className="mb-1">Gender</label>
                          <span className="mb-0 font-weight-bold d-block text-dark">
                            Female
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-4">
                      <div className="d-flex">
                        <i className="font-xll text-primary align-self-center flaticon-bar-chart"></i>
                        <div className="feature-info-content pl-3">
                          <label className="mb-1">Career Level</label>
                          <span className="mb-0 font-weight-bold d-block text-dark">
                            Executive
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-md-0 mb-4">
                      <div className="d-flex">
                        <i className="font-xll text-primary align-self-center flaticon-apartment"></i>
                        <div className="feature-info-content pl-3">
                          <label className="mb-1">Industry</label>
                          <span className="mb-0 font-weight-bold d-block text-dark">
                            Management
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-sm-0 mb-4">
                      <div className="d-flex">
                        <i className="font-xll text-primary align-self-center flaticon-medal"></i>
                        <div className="feature-info-content pl-3">
                          <label className="mb-1">Experience</label>
                          <span className="mb-0 font-weight-bold d-block text-dark">
                            2 Years
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="d-flex">
                        <i className="font-xll text-primary align-self-center flaticon-mortarboard"></i>
                        <div className="feature-info-content pl-3">
                          <label className="mb-1">Qualification</label>
                          <span className="mb-0 font-weight-bold d-block text-dark">
                            Bachelor Degree
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="my-4 my-lg-5">
                      <h5 className="mb-3 mb-md-4">Job Description</h5>

                      <p></p>
                      <p>
                        {Object.entries(dd).map(([key, value]) => {
                          console.log(key,value);
                          return (
                            <div>
                              <span>
                                {" "}
                                <b>{key}--</b>
                                
                                {value}
                              </span>
                            </div>
                          );
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <SideBar history={dd} />
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default TJobList;
