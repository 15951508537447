import React, { Component } from "react";
import banner from "../assets/images/bg/banner-01.jpg";
import { getjob } from "../services/postjobService";

class Banner extends Component {
  state = {
    searchQuery: "",
    location:"",
    searchjobs: [],
  };

  async componentDidMount() {
    const dl = await getjob();
    this.setState({ searchjobs: dl.data });
  }

  handleSearch = (e) => {
    const q = e.currentTarget.value;
    this.setState({ searchQuery: q });
  };
  handlelocation = (e) => {
    const r = e.currentTarget.value;
    this.setState({ location: r });
    
  };

  handlefindjob = (z) => {
    console.log(this.state.searchjobs);
    let filtered = [];
    filtered = this.state.searchjobs.filter(
      (m) =>
      m.jobtitle
      .toLowerCase()
      .includes(this.state.searchQuery.toLowerCase()) ||
        m.category
          .toLowerCase()
          .includes(this.state.searchQuery.toLocaleLowerCase()) ||
        m.location
          .toLowerCase()
          .includes(this.state.location.toLowerCase())||
        m.skills
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase())
       
    );
    if(filtered.length >0){
      this.props.history.push({
        pathname:"/searchpage",
        state:filtered,
      })
    }

    // console.log(filtered);
    // this.props.history.push("/searchpage",filtered);

  };
  render() {
    return (
      <section
        id="img"
        className="banner bg-holder bg-overlay-black-30 text-white"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="text-white mb-3">
                Drop <span className="text-primary"> Resume & Get </span> Your
                Desired Job
              </h1>
              <p className="lead mb-4 mb-lg-5 font-weight-normal">
                Find Jobs, Employment & Career Opportunities
              </p>
              <div className="job-search-field">
                <div className="job-search-item">
                  <form className="form row">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <div className="d-flex">
                          <label>What</label>
                          <span className="ml-auto">
                            e.g. job, company, title
                          </span>
                        </div>
                        <div className="position-relative left-icon">
                          <input
                            type="text"
                            className="form-control"
                            name="job_title"
                            placeholder="Job title, Skills or Company"
                            value={this.state.searchQuery}
                            onChange={this.handleSearch}
                          />
                          <i className="fas fa-search"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="form-group">
                        <div className="d-flex">
                          <label>Where</label>
                          <span className="ml-auto">e.g. city </span>
                        </div>
                        <div className="position-relative left-icon">
                          <input
                            type="text"
                            className="form-control location-input"
                            name="job_title"
                            placeholder="Location"
                            value={this.state.locationQuery}
                            onChange={this.handlelocation}
                          />
                          <i className="far fa-compass"></i>
                          {/* <Link to="#">
                            <div className="detect">
                              <span className="d-none d-sm-block">Detect</span>
                              <i className="fas fa-crosshairs"></i>
                            </div>
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-12">
                      <div className="form-group form-action">
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                          onClick={this.handlefindjob}
                        >
                          <i className="fas fa-search"></i> Find Jobs
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Banner;


