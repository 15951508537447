import React, { Component } from "react";
import Joi from "joi";

class PersonalDetails extends Component {
  state = {
    name: "",
    email: "",
    password: "",
    contactNumber: "",
    resumeURL: "",
    Gender:"",
    preferedworklocation:"",
    skills:"",
    gender: ["Male", "Female", "Others"]
  };
  schema = {
    name: Joi.string().required().label("Name"),
    email: Joi.string().required().label("Email"),
    password: Joi.string().required().label("Password"),
    contactNumber: Joi.number().required().label("Mobile Number"),
    preferedworklocation: Joi.string().required().label("Prefered work location"),
    resumeURL: Joi.string().label("resumeURL"),
    skills: Joi.string().required().label("skills"),
    gender: Joi.string().required().label("gender"),
  };
  handlegender = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      this.setState({ Gender: e.target.value });
    }
  };
  render() {
    return (
      
      <React.Fragment>
        <div className="row">
          
          <div className="col-md-4">
            <label className="text-start">Name *</label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="exampleInputName"
              placeholder="Please enter your name"
              value={this.state.name}
              onChange={(name) => this.setState({ name: name.target.value })}
            />
          </div>
          <div className="col-md-4">
            <label className="text-start">Email *</label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="exampleInputName"
              placeholder="Please enter your email"
              value={this.state.email}
              onChange={(email) => this.setState({ email: email.target.value })}
            />
          </div>
          <div className="col-md-4">
            <label className="text-start">Password *</label>
            <input
              type="password"
              className="form-control form-control-sm"
              id="exampleInputName"
              placeholder="Please enter your password"
              value={this.state.password}
              onChange={(password) => this.setState({ password: password.target.value })}
            />
          </div>
        </div>
        <div className="row">
          
          <div className="col-md-4">
            <label className="text-start">Contact Number*</label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="exampleInputName"
              placeholder="Please enter your contactNumber"
              value={this.state.contactNumber}
              onChange={(contactNumber) => this.setState({ contactNumber: contactNumber.target.value })}
            />
          </div>
          <div className="col-md-4">
            <label className="text-start">Skills*</label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="exampleInputName"
              placeholder="Please enter your skills"
              value={this.state.skills}
              onChange={(skills) => this.setState({ skills: skills.target.value })}
            />
          </div>
          <div className="col-md-4">
            <label className="text-start">Prefered Work Location *</label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="exampleInputName"
              placeholder="Please enter your preferedworklocation"
              value={this.state.preferedworklocation}
              onChange={(preferedworklocation) => this.setState({ preferedworklocation: preferedworklocation.target.value })}
            />
          </div>
        </div>
        <div className="row">
          
          <div className="form-group col-md-4 select-border">
              <label>Gender *</label>
              <select
                className="form-control"
                onChange={(e) => this.handlegender(e)}>
                {this.state.gender.map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>
            </div>
            <div className="form-group col-md-4">
              <label>Upload Resume *</label>
              <input
                type="file"
                className="form-control"
                placeholder="Please choose your Resume"
                value={this.state.resumeURL}
                onChange={(resumeURL) =>
                  this.setState({ resumeURL: resumeURL.target.value })
                }
              />
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PersonalDetails;
