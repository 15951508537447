import React, { Component } from "react";
import {Link} from 'react-router-dom'

class footer extends Component {
  state = {};
  render() {
    return (
      <footer className="footer bg-light">
        <div className="container pt-1">
          <div className="row mt-5">
            <div className="col-lg-3 col-md-6">
              <div className="footer-link">
                <h5 className="text-dark mb-4">Trending Job</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to="#">Browse Jobs</Link>
                  </li>
                  <li>
                    <Link to="#">Browse Categories</Link>
                  </li>
                  <li>
                    <Link to="#">Submit Resume</Link>
                  </li>
                  <li>
                    <Link to="#">Candidate Dashboard</Link>
                  </li>
                  <li>
                    <Link to="#">Job Alerts</Link>
                  </li>
                  <li>
                    <Link to="#">My Bookmarks</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mt-4 mt-md-0">
              <div className="footer-link">
                <h5 className="text-dark mb-4">Trending Companies</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to="#">Shortcodes</Link>
                  </li>
                  <li>
                    <Link to="#">Job Page</Link>
                  </li>
                  <li>
                    <Link to="#">Job Page Alternative</Link>
                  </li>
                  <li>
                    <Link to="#">Resume Page</Link>
                  </li>
                  <li>
                    <Link to="#">Blog</Link>
                  </li>
                  <li>
                    <Link to="#">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
              <h5 className="text-dark mb-4">Subscribe Us</h5>
              <div className="footer-subscribe">
                <p>
                  Sign Up to our Newsletter to get the latest news and offers.
                </p>
                <form>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Enter your Email"
                    />
                  </div>
                  <button type="submit" className="btn btn-primary btn-md">
                    Get Notified
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
              <h5 className="text-dark mb-4">Download App</h5>
              <div className="footer-subscribe">
                <p>Download the latest Slick new job apps now</p>
                <div className="d-inline-block">
                  <Link className="btn btn-white btn-sm btn-app " to="#">
                    <i className="fab fa-apple"></i>
                    <div className="btn-text text-left">
                      <small>Download on the </small>
                      <span className="mb-0 d-block">App Store</span>
                    </div>
                  </Link>
                  <Link className="btn btn-white btn-sm btn-app mt-3" to="#">
                    <i className="fab fa-google-play"></i>
                    <div className="btn-text text-left">
                      <small>Get it on </small>
                      <span className="mb-0 d-block">Google Play</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom bg-dark mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 ">
                <div className="d-flex justify-content-md-start justify-content-center">
                  <ul className="list-unstyled d-flex mb-0">
                    <li>
                      <Link to="#">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="">About</Link>
                    </li>
                    <li>
                      <Link to="#">Team</Link>
                    </li>
                    <li>
                      <Link to="">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 text-center text-md-right mt-4 mt-md-0">
                <p className="mb-0">
                  {" "}
                  &copy;Copyright{" "}
                  <span id="copyright">
                    {" "}
                    <script>
                      document.getElementById('copyright').appendChild(document.createTextNode(new
                      Date().getFullYear()))
                    </script>
                  </span>{" "}
                  <Link to="#"> CG Job Portal </Link> All Rights Reserved{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default footer;
