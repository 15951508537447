import React, { Component } from "react";
import Header from "./header";
import Banner from "./banner";
import Sector from "./sector";
import Jobs from "./jobs";
import Post from "./post";
import Clients from "./clients";
import Footer from "./footer";

class Home extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Header />
        <Banner history={this.props.history} />
        <Sector />
        <Jobs />

        <Post />
        <Clients />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
