import React, { Component } from 'react';


class Step1 extends Component {
    state = {  }
    render() { 
        return ( 
            <React.Fragment>
              <div className="row">
                    <div className="form-group col-md-6">
              <label for="exampleInputPassword1">Name *</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your name"
                // value={this.state.name}
                // onChange={(name) => this.setState({ name: name.target.value })}
              />
            </div>
            <div className="form-group col-md-6">
              <label for="exampleInputPassword1">Password *</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your name"
                // value={this.state.name}
                // onChange={(name) => this.setState({ name: name.target.value })}
              />
            </div>
            <div className="form-group col-md-6">
              <label for="exampleInputPassword1">Email*</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your name"
                // value={this.state.name}
                // onChange={(name) => this.setState({ name: name.target.value })}
              />
            </div>
            <div className="form-group col-md-6">
              <label for="exampleInputPassword1">Email*</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your name"
                // value={this.state.name}
                // onChange={(name) => this.setState({ name: name.target.value })}
              />
            </div>
            <div className="form-group col-md-6">
              <label for="exampleInputPassword1">Email*</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your name"
                // value={this.state.name}
                // onChange={(name) => this.setState({ name: name.target.value })}
              />
            </div>
            </div>
                
            </React.Fragment>
         );
    }
}
 
export default Step1;