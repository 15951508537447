import React, { Component } from "react";
import PBanner from "./profilebanner";
import Header from "../header";
import Footer from "../footer";
import Dashboard from "./dashboard";
import map from "../../assets/images/map.png";
import {Link} from "react-router-dom";

class Profile extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Header />
        <PBanner />
        <Dashboard />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="user-dashboard-info-box">
                  <div className="section-title-02 mb-2">
                    <h4>Basic Information</h4>
                  </div>
                  <div className="cover-photo-contact">
                    <div className="upload-file">
                      <div className="custom-file">
                        <input type="file" className="custom-file-input" />
                        <label className="custom-file-label">
                          Upload Cover Photo
                        </label>
                      </div>
                    </div>
                  </div>
                  <form>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label>Your Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value="Felica Queen"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Email</label>
                        <input
                          type="email"
                          className="form-control"
                          value="felicaqueen@gmail.com"
                        />
                      </div>
                      <div className="form-group col-md-6 datetimepickers">
                        <label>Date of birth</label>
                        <div
                          className="input-group date"
                          id="datetimepicker-01"
                          data-target-input="nearest"
                        >
                          <input
                            type="text"
                            className="form-control datetimepicker-input"
                            value="08/11/1999"
                            data-target="#datetimepicker-01"
                          />
                          <div
                            className="input-group-append"
                            data-target="#datetimepicker-01"
                            data-toggle="datetimepicker"
                          >
                            <div className="input-group-text">
                              <i className="far fa-calendar-alt"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label>Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          value="+(123) 345-6789"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="d-block mb-3">Gender</label>
                        <div className="custom-control custom-radio d-inline">
                          <input
                            type="radio"
                            id="customRadio1"
                            name="customRadio"
                            className="custom-control-input"
                          />
                          <label
                            className="custom-control-label"
                            for="customRadio1"
                          >
                            Male
                          </label>
                        </div>
                        <div className="custom-control custom-radio d-inline ml-3">
                          <input
                            type="radio"
                            id="customRadio2"
                            name="customRadio"
                            className="custom-control-input"
                            checked="checked"
                          />
                          <label
                            className="custom-control-label"
                            for="customRadio2"
                          >
                            Female
                          </label>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label>Job Title</label>
                        <input
                          type="text"
                          className="form-control"
                          value="General Insurance"
                        />
                      </div>
                      <div className="form-group col-md-6 select-border">
                        <label>Job Title</label>
                        <select className="form-control basic-select">
                          <option value="value 01" selected="selected">
                            Test Selector
                          </option>
                          <option value="value 02">Needham, MA</option>
                          <option value="value 03">New Castle, PA</option>
                        </select>
                      </div>

                      <div className="form-group col-md-6">
                        <label>Salary</label>
                        <input
                          type="text"
                          className="form-control"
                          value="$33,000"
                        />
                      </div>
                      <div className="form-group mb-0 col-md-12">
                        <label>Description</label>
                        <textarea
                          className="form-control"
                          rows="5"
                          placeholder="Give yourself the power of responsibility. Remind yourself the only thing stopping you is yourself."
                        ></textarea>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="user-dashboard-info-box">
                  <div className="section-title-02 mb-3">
                    <h4>Social Links</h4>
                  </div>
                  <form>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label>Facebook</label>
                        <input
                          type="text"
                          className="form-control"
                          value="https://www.facebook.com/"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Twitter</label>
                        <input
                          type="email"
                          className="form-control"
                          value="https://www.twitter.com/"
                        />
                      </div>
                      <div className="form-group mb-0 col-md-12">
                        <label>Linkedin</label>
                        <input
                          type="text"
                          className="form-control"
                          value="https://www.linkedin.com/"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="user-dashboard-info-box">
                  <div className="form-group mb-0">
                    <h4 className="mb-3">Address</h4>
                    <div className="form-group">
                      <label>Enter Your Location</label>
                      <input
                        type="text"
                        className="form-control"
                        value="214 labbipet. Vijayawada, 520010"
                      />
                    </div>
                    <div className="company-address-map">
                      <iframe src={map}></iframe>
                    </div>
                  </div>
                </div>
                <Link className="btn btn-md btn-primary" to="#">
                  Save Settings
                </Link>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Profile;
