import jwtDecode from 'jwt-decode';
import http from './httpService';
import {apiUrl} from '../config.json';

const apiEndpoint = apiUrl + '/users/auth';
const tokenKey="token";

http.setJwt(getJwt());

export async function frelogin(obj1){
   const {data:jwt}=await http.post(apiEndpoint,obj1);
   
   localStorage.setItem(tokenKey,jwt); 
}

export function loginWithJwt(jwt){
   localStorage.setItem(tokenKey,jwt);  
}

export function logout(){
   localStorage.removeItem(tokenKey);

}

export function getCurrentUser(){
   try{
      const jwt = localStorage.getItem(tokenKey);
     const ff= jwtDecode(jwt);
     return ff;
     
    }
    catch(ex){
       return null;
    } 
   
}

export function getJwt(){
   return localStorage.getItem(tokenKey);
};

export default {
   frelogin,
   loginWithJwt,
   logout,
   getCurrentUser,
   getJwt
};