import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./header";
import Banner from "./banner";
import Footer from "./footer";
import svg3 from "../assets/images/svg/03.svg";
import { getdetails } from "../services/applicationService";
import { getjob } from "../services/postjobService";
import { toast } from "react-toastify";
import { empjob } from "../services/employerService";
import moment from "moment";

class Viewapplicants extends Component {
  state = {
    applicants: [],
  };
  async componentDidMount() {
    const response = await empjob();
    this.setState({ applicants: response.data });
  }
  render() {
    const dt = this.state.applicants;
    console.log(dt);
    return (
      <React.Fragment>
        <Header />
        <Banner />
        <section className="space-ptb">
          <div className="container">
            <div className="row">
              <div className="row">
                {dt.map((key, i) => (
                  //  <div classname="rowmap">key={i}
                  <div className="col-12">
                    <div className="job-list ">
                      <div className="job-list-logo">
                        <img className="img-fluid" src={svg3} alt="" />
                      </div>
                      <div className="job-list-details">
                        <div className="job-list-info">
                          <div className="job-list-title">
                            <h5 className="mb-0">
                              <Link to="">{key.companyname}</Link>
                            </h5>
                          </div>
                          <div className="job-list-option">
                            <ul className="list-unstyled">
                              <li>
                                {" "}
                                <span>via</span>{" "}
                                <Link to="">{key.jobtitle}</Link>{" "}
                              </li>
                              <li>
                                <i className="fas fa-map-marker-alt pr-1"></i>
                              </li>

                              <li>
                                {" "}
                                <span>
                                  jobtype
                                </span> <Link to="Sector"></Link>{" "}
                              </li>
                              <li>
                                <Link className="freelance" to="#">
                                  <i className="fas fa-suitcase pr-1">
                                    applicants:{" "}
                                    {key.applications.map((i) => {
                                      return (
                                        <p>
                                          {i.user}
                                          <br />
                                          applied Date:{" "}
                                          {moment(i.date).format(
                                            "D MMM YYYY  "
                                          )}
                                        </p>
                                      );
                                    })}
                                  </i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="job-list-favourite-time">
                        {" "}
                        <Link className="job-list-favourite order-2" to="#">
                          <i className="far fa-heart"></i>
                        </Link>{" "}
                        <span className="job-list-time order-1">
                          <i className="far fa-clock pr-1"></i>1M ago
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  // </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Viewapplicants;
