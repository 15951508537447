import React, { Component } from "react";
import svg17 from "../assets/images/svg/17.svg";
import svg18 from "../assets/images/svg/17.svg";
import svg19 from "../assets/images/svg/17.svg";
import map from "../assets/images/map.png";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Input from "./common/input";
import { toast } from "react-toastify";
import applicationService, { apply } from "../services/applicationService";
import auth from "../services/fresherloginService";

class SsideBar extends Component {
  state = {
    types: ["select", "fresher", "exp"],
    type: "",
    user: [],
    jobId: "",
  };

  componentDidMount = () => {
    const user = auth.getCurrentUser();
    this.setState({ user });
    const dt = this.props.history._id;
    this.setState({ jobId: dt });
  };
  handleSubmit = async () => {
    try {
      const data = {
        jobId: this.state.jobId,
      };
      console.log(data);
      const response = await applicationService.apply(data);
      console.log(response);
      if(!response){
        toast.error("please login ", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      else{
        toast.success("Applied successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setTimeout(() => {
        window.location = "/";
      }, 5000);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  render() {
    const dd = this.props.history;

    return (
      <div className="col-lg-4">
        <div className="sidebar mb-0">
          <div className="widget">
          {this.state.user?(
            <Link
              className="btn btn-primary btn-block"
              onClick={this.handleSubmit}
              to=""
            >
              <i className="far fa-paper-plane"></i>Apply for job
            </Link>
          ):(
            <Link
              className="btn btn-primary btn-block"
          
            >
              <i className="far fa-paper-plane"></i>Apply for job
            </Link>
          
          )}
          </div>
          <div className="widget">
            <div className="company-detail-meta">
              <ul className="list-unstyled">
                <li className="linkedin">
                  <Link to="#">
                    <i className="fab fa-linkedin-in"></i>
                    <span className="pl-2">Apply with Linkedin</span>
                  </Link>
                </li>
                <li>
                  <div className="share-box share-dark-bg">
                    <Link to="#">
                      {" "}
                      <i className="fas fa-share-alt"></i>
                      <span className="pl-2">Share</span>
                    </Link>
                    <ul className="list-unstyled share-box-social">
                      <li>
                        {" "}
                        <Link to="www.facebook.com">
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="#">
                          <i className="fab fa-linkedin"></i>
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="#">
                          <i className="fab fa-pinterest"></i>
                        </Link>{" "}
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="widget">
            <div className="company-address widget-box">
              <div className="company-address-map">
                <iframe
                  src={map}
                  // width={"100%"} height={"250"} frameborder={"0"} style={"border:0;"}
                  // allowfullscreen="" aria-hidden="false" tabindex="0"
                ></iframe>
              </div>
              <ul className="list-unstyled mt-3">
                <li>
                  <Link to="#">
                    <i className="fas fa-link fa-fw"></i>
                    <span className="pl-2">www.infojob.com</span>
                  </Link>
                </li>
                <li>
                  <Link to="tel:+905389635487">
                    <i className="fas fa-phone fa-flip-horizontal fa-fw"></i>
                    <span className="pl-2">+(456) 478-2589</span>
                  </Link>
                </li>
                <li>
                  <Link to="mailto:ali.potenza@job.com">
                    <i className="fas fa-envelope fa-fw"></i>
                    <span className="pl-2">support@jobber.demo</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="widget">
            <div className="jobber-company-view">
              <ul className="list-unstyled">
                <li>
                  <div className="widget-box">
                    <div className="d-flex">
                      <i className="flaticon-clock fa-2x fa-fw text-primary"></i>
                      <span className="pl-3">35 Days</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="widget-box">
                    <div className="d-flex">
                      <i className="flaticon-loupe fa-2x fa-fw text-primary"></i>
                      <span className="pl-3">35697 Displayed</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="widget-box">
                    <div className="d-flex">
                      <i className="flaticon-personal-profile fa-2x fa-fw text-primary"></i>
                      <span className="pl-3"> 180 Applied </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="widget">
            <div className="widget-title">
              <h5>Similar Jobs</h5>
            </div>
            <div className="similar-jobs-item widget-box">
              <div className="job-list">
                <div className="job-list-logo">
                  <img className="img-fluid" src={svg17} alt="" />
                </div>
                <div className="job-list-details">
                  <div className="job-list-info">
                    <div className="job-list-title">
                      <h6>
                        <Link to="#">Designer Required</Link>
                      </h6>
                    </div>
                    <div className="job-list-option">
                      <ul className="list-unstyled">
                        <li>
                          <span>via</span>
                          <Link to="employer-detail.html">Trout Design Ltd</Link>
                        </li>
                        <li>
                          <Link className="freelance" to="#">
                            <i className="fas fa-suitcase pr-1"></i>Freelance
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="job-list">
                <div className="job-list-logo">
                  <img className="img-fluid" src={svg18} alt="" />
                </div>
                <div className="job-list-details">
                  <div className="job-list-info">
                    <div className="job-list-title">
                      <h6>
                        <Link to="#">Post Room Operative</Link>
                      </h6>
                    </div>
                    <div className="job-list-option">
                      <ul className="list-unstyled">
                        <li>
                          <span>via</span>
                          <Link to="employer-detail.html">LawnHopper</Link>
                        </li>
                        <li>
                          <Link className="part-time" to="#">
                            <i className="fas fa-suitcase pr-1"></i>Part-Time
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="job-list">
                <div className="job-list-logo">
                  <img className="img-fluid" src={svg19} alt="" />
                </div>
                <div className="job-list-details">
                  <div className="job-list-info">
                    <div className="job-list-title">
                      <h6>
                        <Link to="#">Stockroom Assistant</Link>
                      </h6>
                    </div>
                    <div className="job-list-option">
                      <ul className="list-unstyled">
                        <li>
                          <span>via</span>
                          <Link to="employer-detail.html">Rippin LLC</Link>
                        </li>
                        <li>
                          <Link className="temporary" to="#">
                            <i className="fas fa-suitcase pr-1"></i>Temporary
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="job-list">
                {/* <div className="job-list-logo">
                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 726 726"><path fill="#4D4D4D" d="M332.45 400.625l22.501-81.37c.683-2.276 2.039-3.187 4.321-3.187h18.179c2.505 0 3.644.911 4.328 3.187l23.184 81.827 20.223-66.375h-10.908c-2.277 0-3.415-1.128-3.415-3.405v-11.83c0-2.494 1.138-3.405 3.415-3.405h29.084c3.871 0 5.464 2.959 4.553 6.137l-31.37 103.187c-.674 2.277-2.039 3.188-4.315 3.188h-20.453c-2.275 0-3.642-.911-4.325-3.405l-21.591-80.242-22.5 80.459c-.684 2.277-2.05 3.188-4.316 3.188h-20.461c-2.272 0-3.638-.911-4.316-3.188l-24.778-90.684h-11.814c-2.277 0-3.415-1.128-3.415-3.405v-11.83c0-2.494 1.138-3.405 3.415-3.405h29.772c2.271 0 3.638.911 4.326 3.405l20.676 81.153z"/><path fill="#24BAC9" d="M477.723 542.344c-28.339-15.399-46.472-42.319-51.937-71.734-36.432 21.135-82.665 23.536-122.342 1.946-29.927-16.218-67.384-5.133-83.644 24.798-16.26 29.922-5.169 67.356 24.757 83.648 91.365 49.616 200.396 35.634 276.19-26.724-14.976-.891-29.734-4.74-43.024-11.934z"/><path fill="#F03955" d="M554.54 205.553c-.89 14.78-4.927 29.342-12.213 42.735-14.903 27.499-41.036 46.314-71.745 51.945 21.185 36.43 23.575 82.673 2.018 122.313-16.26 29.921-5.165 67.396 24.747 83.678 29.933 16.239 67.398 5.143 83.649-24.777 49.597-91.254 35.665-200.092-26.456-275.894z"/><path fill="#59B89C" d="M183.66 477.732c14.965-27.51 41.058-46.295 71.761-51.967-21.161-36.421-23.567-82.622-2.013-122.345 16.239-29.911 5.169-67.356-24.758-83.595-29.942-16.28-67.383-5.185-83.643 24.736-49.618 91.254-35.655 200.175 26.517 275.957.879-14.584 4.75-29.186 12.136-42.786z"/><path fill="#F09502" d="M481.447 145.018C390.079 95.36 281.052 109.384 205.26 171.72c14.96.9 29.755 4.751 43.024 11.954 28.333 15.4 46.477 42.269 51.957 71.744 36.437-21.195 82.665-23.557 122.316-2.007 29.933 16.249 67.39 5.174 83.649-24.757 16.26-29.963 5.195-67.376-24.759-83.636z"/></svg>
                </div> */}
                <div className="job-list-details mb-0">
                  <div className="job-list-info">
                    <div className="job-list-title">
                      <h6>
                        <Link to="#">Research Administrator</Link>
                      </h6>
                    </div>
                    <div className="job-list-option">
                      <ul className="list-unstyled">
                        <li>
                          <span>via</span>
                          <Link to="employer-detail.html">Trophy and Sons</Link>
                        </li>
                        <li>
                          <Link className="full-time" to="#">
                            <i className="fas fa-suitcase pr-1"></i>Full time
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SsideBar;
