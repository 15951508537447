import http from "./httpService";
import { apiUrl} from '../config.json';

const apiEndpoint = apiUrl +"/employers/reg";
const apiEndpointjob=apiUrl+"/employers/jobemp";
const apiEndpointget=apiUrl+"/employers/get";

export function empregister(employer){
    //console.log(employer)
    return http.post(apiEndpoint,{
        name:employer.name,
        employeremail:employer.employeremail,
        password:employer.password,
        contactNumber:employer.contactNumber,
        industry:employer.industry,
        companyname:employer.companyname,
        location:employer.location,    
    })
}
export function empjob(){
    return http.post(apiEndpointjob)
}
export function empget(){
    return http.post(apiEndpointget)
}

export default {
    empregister,
    empjob,
    empget
};