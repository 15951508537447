import React, { Component } from 'react';
import { toast } from "react-toastify";
import { eregister } from "../../services/experienceService";

class EducationDetails extends Component {
    state = { 
      var: "",
      education:{
       
      Highestqualifications:["select",
      "Doctorate",
      "Masters/Post-Graduation",
      "Graduation/Diploma"],
      Course:["select",
        "Ph.D/Doctorate",
        "MPHIL",
        "MS/M.Sc (Science)",
  "MBA/PGDM",
  "M.A",
	"M.Com",
	"MCA",
	"M.Tech",
	"Integrated PG",
  "PG Diploma",
  "B.Tech/B.E.",
"B.Sc",
"B.Com",
"Diploma",
"BCA",
"B.B.A/B.M.S",
"B.Ed",
"B.Pharma",
	      "Other"
      ],
      Specialization:["select",
      "Advertising/Mass Communication",
        "Agriculture",
        "Anthropology",
        "Architecture",
        "Arts & Humanities",
        "Astrophysics",
        "Automobile",
        "Aviation",
        "Bio-Chemistry/Bio-Technology",
        "Biomedical",
        "Biophysics",
        "Biotechnology",
        "Botany",
      "Ceramics",
      "Aerospace & Mechanical Engineering",
"Agriculture",
"Anthropology",
"Astronautical Engineering",
"Bio-chemistry",
"Biology",
"Biotechnology",
"Botany",
"Chemical Engineering",
"Cyber Security Engineering",
"Computers",
"Dairy Technology",
"Data Informatics",
"Electrical Engineering",
"Electronics",
"Mechanical Engineering",
"Information Technology",
"Agriculture",
"Automation",
"Ceramics",
"Chemical",
"Computers",
"Electrical",
"Electronics/Telecommunication",
"Mechanical",
"Others",
      ],
      college:"",
      coursetype:"",
      passingyear:""
     }
    }
    radiohandleChange = async (value) => {
      await this.setState({ var: value });
      console.log(this.state.var);
    };
    handlehighestqualifications = (e) => {
      //console.log(e.target.value);
      if (e.target.value) {
        this.setState({ highestQualifications: e.target.value });
      }
    };
    handleCourses = (e) => {
      //console.log(e.target.value);
      if (e.target.value) {
        this.setState({ Course: e.target.value });
      }
    };
    handleSpecializations = (e) => {
      console.log(e.target.value);
      if (e.target.value) {
        this.setState({ Specialization: e.target.value });
      }
    };
    handleSubmit = async () => {
    await this.setState({expbtn:"true"})
    if (this.state) {
    }
    try {
      const data = {
        name: this.state.name,
        contactNumber: this.state.contactNumber,
        password: this.state.password,
        email: this.state.email,
        gender:this.state.gender,
        preferedworklocation:this.state.preferedworklocation,
        skills:this.state.skills,
        employement: this.state.employement,
        education: this.state.education,
        resumeURL:this.state.resumeURL
        
      };

      const response = await eregister(data);
      // auth.emploginWithJwt(response.headers["x-auth-token"]);
      // localStorage.setItem("token", jwt);
      if(!response){
        toast.error("fill all details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      else{
      toast.success("successfully register", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setTimeout(() => {
        window.location = "/";
      }, 5000);
    }
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
    render() { 
        return ( 
            <React.Fragment>
                <div>
                    <p>Educationdetails Form</p>
                    <div className="row">
                    <div className="form-group col-md-4 select-border">
              <label>Highest Qualification *</label>
              <select
                className="form-control"
                onChange={(e) => this.handlehighestqualifications(e)}
              >
                {this.state.education.Highestqualifications.map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>
            </div>
                    
            <div className="form-group col-md-4 select-border">
              <label>Course *</label>
             
              <select
                className="form-control"
                onChange={(e) => this.handleCourses(e)}
              >
                {this.state.education.Course.map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>
            </div>
            <div className="form-group col-md-4 select-border">
              <label>Specialization *</label>
              <select
                className="form-control"
                onChange={(e) => this.handleSpecializations(e)}
              >
                {this.state.education.Specialization.map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>
            </div>
           
            <div className="form-group col-md-4">
              <label for="exampleInputPassword1">College/University*</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your name"
                value={this.state.College}
                onChange={(College) =>
                  this.setState({ College: College.target.value })
                }
              />
            </div>
            <div
              className="col-md-4"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <label>Course Type *</label>
              <div className="row">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value={this.state.var}
                    onChange={() => this.radiohandleChange("Fulltime")}
                  />
                  <label class="form-check-label " for="inlineRadio1">
                    Full Time
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value={this.state.var}
                    onChange={() => this.radiohandleChange("Parttime")}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Part Time
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    value={this.state.var}
                    onChange={() => this.radiohandleChange("correspondence")}
                  />
                  <label class="form-check-label" for="inlineRadio3">
                    Correspondence
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group col-md-4">
              <label for="exampleInputPassword1">Passing Year</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="exampleInputName"
                placeholder="Please enter your Passing year"
                value={this.state.passingyear}
                onChange={(passingyear) => this.setState({ passingyear: passingyear.target.value })}
              />
            </div>
            </div>
                    
                  
                    {/* <div class="row"> */}
            <button
              type="button"
              className="btn btn-primary col-sm-2"
             onClick={this.handleSubmit}
            >
              Submit
            </button>
          {/* </div> */}
                </div>
            </React.Fragment>
         );
    }
}
 
export default EducationDetails;