import http from "./httpService";
import { apiUrl} from '../config.json';

const apiEndpoint = apiUrl +"/eusers/register";

export function eregister(euser){
    //console.log(euser)
    return http.post(apiEndpoint,{
        name:euser.name,
        email:euser.email,
        password:euser.password,
        contactNumber:euser.contactNumber,
        gender:euser.gender,
        preferedworklocation:euser.preferedworklocation,
        skills:euser.skills,
        resumeURL:euser.resumeURL,
        education:euser.education,
        employement:euser.employement,
       
          
    })
}
export default eregister;