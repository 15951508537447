import React, { Component } from "react";
import svg1 from "../../assets/images/svg/01.svg";
import svg2 from "../../assets/images/svg/02.svg";
import svg4 from "../../assets/images/svg/04.svg";
import svg5 from "../../assets/images/svg/05.svg";
import Header from "../header";
import Dashboard from "./dashboard";
import PBanner from "./profilebanner";
import Footer from "../footer";
import {Link} from 'react-router-dom';

class CandidateDashboard extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Header />
        <PBanner />
        <Dashboard />

        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row mb-3 mb-lg-5 mt-3 mt-lg-0">
                  <div className="col-lg-4 mb-4 mb-lg-0">
                    <div className="candidates-feature-info bg-dark">
                      <div className="candidates-info-icon text-white">
                        <i className="fas fa-globe-asia"></i>
                      </div>
                      <div className="candidates-info-content">
                        <h6 className="candidates-info-title text-white">
                          Total Applications
                        </h6>
                      </div>
                      <div className="candidates-info-count">
                        <h3 className="mb-0 text-white">01</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4 mb-lg-0">
                    <div className="candidates-feature-info bg-success">
                      <div className="candidates-info-icon text-white">
                        <i className="fas fa-thumbs-up"></i>
                      </div>
                      <div className="candidates-info-content">
                        <h6 className="candidates-info-title text-white">
                          Shortlisted Applications
                        </h6>
                      </div>
                      <div className="candidates-info-count">
                        <h3 className="mb-0 text-white">00</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4 mb-lg-0">
                    <div className="candidates-feature-info bg-danger">
                      <div className="candidates-info-icon text-white">
                        <i className="fas fa-thumbs-down"></i>
                      </div>
                      <div className="candidates-info-content">
                        <h6 className="candidates-info-title text-white">
                          Rejected Applications
                        </h6>
                      </div>
                      <div className="candidates-info-count">
                        <h3 className="mb-0 text-white">00</h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="user-dashboard-info-box mb-0 pb-4">
                  <div className="section-title">
                    <h4>Recent Applied Jobs</h4>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="job-list ">
                        <div className="job-list-logo">
                          <img className="img-fluid" src={svg1} alt="" />
                        </div>
                        <div className="job-list-details">
                          <div className="job-list-info">
                            <div className="job-list-title">
                              <h5 className="mb-0">
                                <Link to="job-detail.html">
                                  Marketing and Communications
                                </Link>
                              </h5>
                            </div>
                            <div className="job-list-option">
                              <ul className="list-unstyled">
                                <li>
                                  {" "}
                                  <span>via</span>{" "}
                                  <Link to="employer-detail.html">
                                    Fast Systems Consultants
                                  </Link>{" "}
                                </li>
                                <li>
                                  <i className="fas fa-map-marker-alt pr-1"></i>
                                  Wellesley Rd, London
                                </li>
                                <li>
                                  <i className="fas fa-filter pr-1"></i>
                                  Accountancy
                                </li>
                                <li>
                                  <Link className="freelance" to="#">
                                    <i className="fas fa-suitcase pr-1"></i>
                                    Freelance
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="job-list-favourite-time">
                          {" "}
                          <Link className="mb-3 d-block order-2" to="#">
                            <i className="far fa-edit"></i>
                          </Link>{" "}
                          <span className="job-list-time order-1">
                            <i className="far fa-clock pr-1"></i>1M ago
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="job-list">
                        <div className="job-list-logo">
                          <img className="img-fluid" src={svg2} alt="" />
                        </div>
                        <div className="job-list-details">
                          <div className="job-list-info">
                            <div className="job-list-title">
                              <h5 className="mb-0">
                                <Link to="job-detail.html">
                                  Web Developer – .net
                                </Link>
                              </h5>
                            </div>
                            <div className="job-list-option">
                              <ul className="list-unstyled">
                                <li>
                                  {" "}
                                  <span>via</span>{" "}
                                  <Link to="employer-detail.html">
                                    Pendragon Green Ltd
                                  </Link>{" "}
                                </li>
                                <li>
                                  <i className="fas fa-map-marker-alt pr-1"></i>
                                  Needham, MA
                                </li>
                                <li>
                                  <i className="fas fa-filter pr-1"></i>IT &amp;
                                  Telecoms
                                </li>
                                <li>
                                  <Link className="part-time" to="#">
                                    <i className="fas fa-suitcase pr-1"></i>
                                    Part-Time
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="job-list-favourite-time">
                          {" "}
                          <Link className="mb-3 d-block order-2" to="#">
                            <i className="far fa-edit"></i>
                          </Link>{" "}
                          <span className="job-list-time order-1">
                            <i className="far fa-clock pr-1"></i>3D ago
                          </span>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="job-list">
                        <div className="job-list-logo">
                          <img className="img-fluid" src={svg4} alt="" />
                        </div>
                        <div className="job-list-details">
                          <div className="job-list-info">
                            <div className="job-list-title">
                              <h5 className="mb-0">
                                <Link to="job-detail.html">
                                  Data Entry Administrator
                                </Link>
                              </h5>
                            </div>
                            <div className="job-list-option">
                              <ul className="list-unstyled">
                                <li>
                                  {" "}
                                  <span>via</span>{" "}
                                  <Link to="employer-detail.html">
                                    Tan Electrics Ltd
                                  </Link>{" "}
                                </li>
                                <li>
                                  <i className="fas fa-map-marker-alt pr-1"></i>
                                  Park Avenue, Mumbai
                                </li>
                                <li>
                                  <i className="fas fa-filter pr-1"></i>Charity
                                  &amp; Voluntary
                                </li>
                                <li>
                                  <Link className="full-time" to="#">
                                    <i className="fas fa-suitcase pr-1"></i>
                                    Full-time
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="job-list-favourite-time">
                          {" "}
                          <Link className="mb-3 d-block order-2" to="#">
                            <i className="far fa-edit"></i>
                          </Link>{" "}
                          <span className="job-list-time order-1">
                            <i className="far fa-clock pr-1"></i>3M ago
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="job-list">
                        <div className="job-list-logo">
                          <img className="img-fluid" src={svg5} alt="" />
                        </div>
                        <div className="job-list-details">
                          <div className="job-list-info">
                            <div className="job-list-title">
                              <h5 className="mb-0">
                                <Link to="job-detail.html">
                                  Operational manager part-time
                                </Link>
                              </h5>
                            </div>
                            <div className="job-list-option">
                              <ul className="list-unstyled">
                                <li>
                                  {" "}
                                  <span>via</span>{" "}
                                  <Link to="employer-detail.html">
                                    Fleet Home Improvements Pvt
                                  </Link>{" "}
                                </li>
                                <li>
                                  <i className="fas fa-map-marker-alt pr-1"></i>
                                  Green Lanes, London
                                </li>
                                <li>
                                  <i className="fas fa-filter pr-1"></i>
                                  Accountancy (Qualified)
                                </li>
                                <li>
                                  <Link className="part-time" to="#">
                                    <i className="fas fa-suitcase pr-1"></i>
                                    Part-Time
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="job-list-favourite-time">
                          {" "}
                          <Link className="mb-3 d-block order-2" to="#">
                            <i className="far fa-edit"></i>
                          </Link>{" "}
                          <span className="job-list-time order-1">
                            <i className="far fa-clock pr-1"></i>6D ago
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default CandidateDashboard;
