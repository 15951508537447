import React, { Component } from "react";
import Banner from "./banner";
import Header from "./header";
import Footer from "./footer";
import JobList from "./joblist";

class JobDetail extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Header />
        <Banner />
        <JobList />
        <Footer />
      </React.Fragment>
    );
  }
}

export default JobDetail;
