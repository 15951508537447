import React, { Component } from "react";
import Banner from "./banner";
import Header from "./header";
import Footer from "./footer";
import Ebanner from "./ebanner";
import Esidebar from "./esidebar";
import Erightside from "./erightside";

class Companies extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Header />
        <Ebanner />
        <section class="space-ptb">
          <div class="container">
            <div class="row">
              <Esidebar />
              <Erightside />
            </div>
          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Companies;
