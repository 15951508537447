import React, { Component } from "react";
import Joi from "joi-browser";
import jwt from "jsonwebtoken";
import logo from "../assets/images/logo.png";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router-dom";
import employerloginService from "../services/employerloginService";
import { empgetCurrentUser } from "../services/employerloginService";
import jwtDecode from "jwt-decode";
import Form from "./common/form";
import Input from "./common/input";
import Login from "./login";
import Jobs from "../components/jobs";
import JobSeeker from "../components/jobseeker";
import fresherloginService, {
  getCurrentUser,
} from "../services/fresherloginService";

class Header extends Component {
  state = {
    email: "",
    password: "",
    errors: {},
    open: false,
    open1: false,
  };
  schema = {
    employeremail: Joi.string().required().label("Email"),
    password: Joi.string().required().label("Password"),
  };

  componentDidMount = async () => {
     
  window.scrollTo(0, 0);
  
    const user = await empgetCurrentUser();
    this.setState({ user });
    const us = getCurrentUser();
  };

  doSubmit = async () => {
    try {
      const obj = {
        employeremail: this.state.employeremail,
        password: this.state.password,
      };

      const response = await employerloginService.emplogin(obj);
      toast.success("successfully login", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        this.setState({ open: false });
      }, 2000);
      window.location = "/viewjoblist";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onOpenModal1 = () => {
    this.setState({ open1: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };
  onCloseModal1 = () => {
    this.setState({ open1: false });
  };

  render() {
    const { open, open1 } = this.state;

    return (
      <header className="header  bg-dark">
        <nav className="navbar navbar-static-top navbar-expand-lg navbar-light header-sticky">
          <div className="container-fluid">
            <button
              id="nav-icon4"
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            ></button>
            <Link className="navbar-brand" to="">
              <img className="img-fluid" src={logo} alt="logo" />
            </Link>
            <div className="navbar-collapse collapse justify-content-center">
              <ul className="nav navbar-nav">
                <li className="nav-item dropdown active">
                  <Link className="nav-link" to="">
                    Home{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link to="ViewJobList" className="nav-link">
                    {" "}
                    Jobs{" "}
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/companies" className="nav-link">
                    {" "}
                    Companies{" "}
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/viewapplicants" className="nav-link">
                    {" "}
                    Applicants{" "}
                  </Link>
                </li>

                <li>
                  {!this.state.us ? (
                    <Link className="nav-link" onClick={this.onOpenModal1}>
                     <i className="far fa-user pr-2"></i> Job Seeker
                    </Link>
                  ) : (
                    <div class="dropdown">
                      <span>
                        <i className="far fa-user pr-2"></i>{" "}
                        {this.state.us.name}
                      </span>
                      <div class="dropdown-content">
                        <Link className="nav-link" to="/logout">
                          Job Seeker
                        </Link>
                      </div>
                    </div>
                  )}
                </li>
              </ul>
            </div>
            <div className="add-listing">
              <div className="row"></div>
              <div button className="login d-inline-block mr-4">
                {!this.state.user ? (
                  <Link
                    onClick={this.onOpenModal}
                    to=""
                    data-toggle="modal"
                    data-target="exampleModalCenter"
                  >
                    <i className="far fa-user pr-2"></i> Employer
                  </Link>
                ) : (
                  <div class="dropdown">
                    <span className="text-white">
                      <i className="far fa-user pr-2"></i>{" "}
                      {this.state.user.name}
                    </span>
                    <div class="dropdown-content">
                      <Link class="dropdown-item" to="/logout">
                        Logout
                      </Link>
                    </div>
                  </div>
                )}
              </div>
              <Modal open={open} onClose={this.onCloseModal} center={true}>
                <div class="modal-content">
                  <div class="modal-header p-4">
                    <h4 class="mb-0 text-center"> Login To Your Account</h4>
                  </div>
                  <div class="modal-body">
                    <div class="login-register">
                      <fieldset>
                        <legend class="px-2">Employer Login Here</legend>
                        <ul class="nav nav-tabs nav-tabs-border d-flex">
                          <li class="nav-item mr-4">
                            <Link
                              class="nav-link active"
                              data-toggle="tab"
                              role="tab"
                              aria-selected="false"
                            >
                              <div class="d-flex">
                                <div class="tab-icon">
                                  <i class="flaticon-suitcase"></i>
                                </div>
                                <div class="ml-3">
                                  <h6 class="mb-0">Employer</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </fieldset>
                      <div class="tab-content">
                        <div
                          class="tab-pane active"
                          id="employer"
                          role="tabpanel"
                        >
                          <div class="form-row">
                            <div class="form-group col-12">
                              <label class="form-label">Email address</label>
                              <input
                                type="email"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Enter Your Email"
                                label="Email"
                                value={this.state.employeremail}
                                onChange={(email) =>
                                  this.setState({
                                    employeremail: email.target.value,
                                  })
                                }
                              />
                            </div>
                            <div class="form-group col-12">
                              <label class="form-label">Password</label>
                              <input
                                type="password"
                                class="form-control"
                                placeholder="Enter Your Password"
                                value={this.state.password}
                                onChange={(password) =>
                                  this.setState({
                                    password: password.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-md-6">
                              <button
                                onClick={this.doSubmit}
                                class="btn btn-primary"
                              >
                                Sign In
                              </button>
                            </div>
                            <div class="col-md-6">
                              <div class="ml-md-3 mt-3 mt-md-0 forgot-pass">
                                <p class="mt-1">
                                  Don't have account?{" "}
                                  <Link to="employer">Sign Up here</Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal open={open1} onClose={this.onCloseModal1} center={true}>
                <div class="modal-content">
                  <div class="modal-header p-4">
                    <h4 class="mb-0 text-center">Login to Your Account</h4>
                  </div>
                  <div className="modal-body">
                    <JobSeeker />
                  </div>
                </div>
              </Modal>
              {this.state.user && (
                <Link className="btn btn-white btn-md" to="Postjob">
                  <i className="fas fa-plus-circle"></i>Post a job
                </Link>
              )}
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
